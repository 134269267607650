// noinspection NpmUsedModulesInstalled
import { ATTENDANCE_TIME_SHEET_TRANSLATIONS } from '@app_balabaqsha/consts';

// noinspection NpmUsedModulesInstalled
import { QBtn } from '@quasar/components';

import { Helper } from '@common/src/helpers';
import Localization from '../../views/Mixins/QuasarLocale';

export default {
  name: 'VDigitalSignBtn',
  components: {
    QBtn,
  },
  mixins: [Localization],
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'sign',
    },
    color: {
      type: String,
      default: 'green',
    },
    marginTop: {
      type: String,
      default: '0px',
    },
  },
  // data() {
  //   // eslint-disable-next-line camelcase
  //   const { core_project } = window;
  //   const locale = core_project.locale || 'kz';
  //   // eslint-disable-next-line camelcase
  //
  //   const {
  //     label, disable, url, color,
  //   } = this;
  //
  //   return {
  //     locale,
  //     label,
  //     disable,
  //     url,
  //     color,
  //   };
  // },
  beforeCreate() {
    this.$trans.add(ATTENDANCE_TIME_SHEET_TRANSLATIONS);
  },
  methods: {
    async sign() {
      this.$globalLoading.show();
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = '<status>Sign</status>';
      if ([webSocket.CLOSED, webSocket.CLOSING].includes(webSocket.readyState)) {
        this.$notify({
          text: this.trans('notice.ncalayer_connection_error'),
          type: 'error',
        });
        return;
      }
      webSocket.onopen = () => {
        callback = async (response) => {
          this.signXml = response.responseObject;
          const res = await requestWrapper.call(this, {
            url: this.url,
            data: {
              signXml: this.signXml,
            },
            method: 'post',
          });
          if (!res.error) {
            window.location.reload();
          } else {
            Helper.handlerResponseErrorNew(this, res);
            this.$globalLoading.hide();
          }
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };

      webSocket.onclose = (event) => {
        if (event.wasClean) {
          // eslint-disable-next-line no-console
          console.warn('connection has been closed');
        } else {
          this.$notify({
            text: this.trans('notice.ncalayer_connection_closed'),
            type: 'error',
          });
          this.$globalLoading.hide();
        }
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.$globalLoading.hide();
          }
        }
      };
    },
  },
};
