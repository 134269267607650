<template>
  <v-spoiler :title="trans('fc_reports.desc')">
    <div class="row">
      <div
        v-for="(itm, key) in item"
        :key="key"
        class="col-lg-4 col-md-4 col-sm-12 s-desc-item"
      >
        <div class="s-desc-item__label">
          {{ itm.label }}:
        </div>
        <div class="s-desc-item__value">
          {{ itm.value }}
        </div>
      </div>
    </div>
  </v-spoiler>
</template>

<script>
import VSpoiler from '@common/components/VSpoiler';

export default {
  name: 'VVoucherFcReportDesc',
  components: { VSpoiler },
  props: {
    item: {
      type: Array,
      required: true,
    },
  },
};

</script>
