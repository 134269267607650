import axios from 'axios';

export default {
  name: 'MobileMenu',
  props: {
    user: {
      type: String,
      default: '',
    },
    urls: {
      type: Object,
      default: () => {
      },
    },
    menu: {
      type: Array,
      default: () => ([]),
    },
    currentPlace: {
      type: Object,
      default: () => {
      },
    },
    langs: {
      type: Object,
      default: () => {
      },
    },
    withoutLocality: {
      type: Boolean,
      default: false,
    },
    withoutDepartment: {
      type: Boolean,
      default: false,
    },
    withSupport: {
      type: Boolean,
      default: true,
    },
    showWhatsapp: {
      type: Boolean,
      default: true,
    },
    showTelegram: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      submenu: [],
      isActive: false,
      currentLocale: window.core_project.locale,
      currentRegion: window.core_project.segment_region,
      menuPage: 'main',
      regions: [],
      selected: {
        region: {},
        dept: {},
        loc: {},
      },
      routeLength: 4,
      loader: false,
      mapMenu: this.menu,
    };
  },
  computed: {
    position() {
      if (this.currentPlace.loc) {
        return this.currentPlace.loc;
      }
      if (this.currentPlace.dept) {
        return this.currentPlace.dept;
      }
      if (this.currentPlace.region) {
        return this.currentPlace.region;
      }
      return this.trans('label.choose_region');
    },
    transition() {
      if (this.menuPage.length < this.routeLength) {
        this.routeLength = this.menuPage.length;
        return 'swipe-right';
      }
      this.routeLength = this.menuPage.length;
      return 'swipe-left';
    },
  },
  watch: {
    isActive() {
      if (this.isActive) {
        document.body.classList.add('modal-open', 'mobile', 'touch', 'platform-ios');
      } else {
        document.body.classList.remove('modal-open', 'mobile', 'touch', 'platform-ios');
      }
    },
  },
  beforeCreate() {
    this.$trans.add(['button', 'label', 'auth', 'contact', 'cabinet', 'notice']);
  },
  mounted() {
    _.forEach(this.menu, (item) => {
      if (item.active && item.submenu) {
        this.setSubmenu(item);
      }
    });
    if (window.innerWidth <= 1200 && !window.core_project.not_select_region) {
      this.pullPlaces('regions');
      this.menuPage = 'regions';
      this.isActive = true;
    }
    // until v-app is mounted in school
    document.querySelectorAll('.vue-mobile-menu').forEach((target) => {
      target.addEventListener('click', () => {
        this.isActive = true;
      });
    });
    this.swipe();
  },
  beforeDestroy() {
    document.querySelectorAll('.vue-mobile-menu').forEach((target) => {
      target.removeEventListener('click', () => {
        this.isActive = true;
      });
    });
  },
  methods: {
    setSubmenu(submenu) {
      this.$set(submenu, 'activeSubmenu', !submenu.activeSubmenu);
    },
    localityLink(lang) {
      const args = window.location.pathname.split('/');
      const currentLink = args.slice(3).join('/');
      return `/${lang.locale}/${this.currentRegion}/${currentLink}${window.location.search}`;
    },
    selectParent(parent, parentKey, children) {
      this.selected[parentKey] = parent;
      if (!this.selected[parentKey][children]) {
        this.pullPlaces(children, this.selected[parentKey]);
        return;
      }
      this.menuPage += `/${children}`;
    },
    routeUp() {
      if (this.menuPage.includes('/')) {
        this.menuPage = this.menuPage.slice(0, this.menuPage.lastIndexOf('/'));
      } else {
        this.menuPage = 'main';
      }
    },
    pullPlaces(type, parent) {
      this.loader = true;
      // Second argument for relative URLs, everything after hostname will be stripped
      const url = new URL(this.urls[type], window.location.href);
      if (parent) {
        url.searchParams.set('id', parent.id);
      }
      axios.get(url.toString())
        .then((response) => {
          if (parent) {
            this.$set(parent, type, response.data);
          } else {
            this.$set(this, type, response.data);
          }
          this.menuPage += `/${type}`;
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            text: error.message,
          });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    toRegions() {
      if (this.regions.length === 0) {
        this.pullPlaces('regions');
        return;
      }
      this.menuPage = 'regions';
    },
    swipe() {
      const vm = this;
      let xDown = null;
      let xUp = null;

      function handleTouchStart(evt) {
        xDown = evt.changedTouches[0].clientX;
      }

      function handleTouchEnd(evt) {
        xUp = evt.changedTouches[0].clientX;
        if (vm.isActive && (xUp - xDown > 25)) {
          vm.isActive = false;
        } else if (!vm.isActive && (window.innerWidth - xDown < 25) && (xUp - xDown < -25)) {
          vm.isActive = true;
        }
      }

      document.addEventListener('touchstart', handleTouchStart, false);
      document.addEventListener('touchend', handleTouchEnd, false);
    },
  },
};
