// noinspection NpmUsedModulesInstalled
import {
  COMMON_FREE_PLACE_TYPE,
  EARLY_FREE_PLACE_TYPE,
  TEMPORARY_FREE_PLACE_TYPE,
} from '@app_balabaqsha/consts';
import _ from 'lodash';

const Fields = {
  props: {
    defaultBudget: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    const { defaultBudget } = this;
    return {
      selectedGroupId: null,
      selectedTypeId: null,
      dateActive: null,
      dateStart: null,
      dateEnd: null,
      placeCount: null,
      agePlaceCount: {},
      budget: defaultBudget,
      hasCorrection: false,
      hasSanatorium: false,
      disease: [],
      sanatoriumType: null,
      freePlaceTypes: {},
    };
  },
  computed: {
    dateStartProxy: {
      get() {
        if (this.selectedTypeId === COMMON_FREE_PLACE_TYPE) {
          return this.dateActive;
        }
        return this.dateStart;
      },
      set(value) {
        this.dateStart = value;
      },
    },
    sanatorium: {
      get() {
        return this.hasSanatorium;
      },
      set(value) {
        if (
          this.groupCorrection
          && this.groupCorrection.value === 5
        ) {
          this.hasSanatorium = value;
        }

        if (
          this.groupCorrection
          && (this.groupCorrection.value === 4
          || this.groupCorrection.value === 5)
        ) {
          const { dataConfig: { freePlaceTypes } } = this;
          const index = _.findIndex(freePlaceTypes, item => item.id === 1);
          this.setSelectedType(freePlaceTypes[index], true);
        } else {
          this.disease = null;
        }
      },
    },
    correction: {
      get() {
        return this.hasCorrection;
      },
      set(value) {
        if (
          this.groupCorrection
          && this.groupCorrection.value === 3
        ) {
          this.hasCorrection = value;
        }

        if (
          this.groupCorrection
          && (this.groupCorrection.value === 2
          || this.groupCorrection.value === 3)
        ) {
          const { dataConfig: { freePlaceTypes } } = this;
          const index = _.findIndex(freePlaceTypes, item => item.id === 1);
          this.setSelectedType(freePlaceTypes[index], true);
        } else {
          this.disease = null;
        }
      },
    },
    sanatoria: {
      get() {
        return this.hasSanatorium;
      },
      set(value) {
        if (
          this.groupCorrection
          && this.groupCorrection.value === 5
        ) {
          this.hasSanatorium = value;
        }

        if (
          this.groupCorrection
          && (this.groupCorrection.value === 4
          || this.groupCorrection.value === 5)
        ) {
          const { dataConfig: { freePlaceTypes } } = this;
          const index = _.findIndex(freePlaceTypes, item => item.id === 1);
          this.setSelectedType(freePlaceTypes[index], true);
        } else {
          this.sanatoriumType = null;
        }
      },
    },
    budgetProxy: {
      get() {
        return true;
        if (
          this.isPrivate !== undefined
          && this.isPrivate
          && this.selectedTypeId === EARLY_FREE_PLACE_TYPE
        ) {
          return true;
        }
        return this.budget || false;
      },
      set(value) {
        this.budget = value;
      },
    },
    selectedGroupDiseases() {
      const { selectedGroup } = this;
      if (!selectedGroup || !selectedGroup.diseases) {
        return [];
      }
      return selectedGroup.diseases.map(item => ({
        ...item,
        value: item.id,
        label: item.name,
      }));
    },
    selectedGroupSanatorium() {
      const { selectedGroup } = this;
      if (!selectedGroup || !selectedGroup.sanatorium) {
        return [];
      }
      return selectedGroup.sanatorium.map(item => ({
        ...item,
        value: item.id,
        label: item.name,
      }));
    },
    selectedGroup() {
      const { groups, selectedGroupId } = this;
      const index = _.findIndex(
        groups,
        group => group.id === selectedGroupId,
      );
      return groups[index];
    },
    selectedType() {
      const { freePlaceTypes, selectedTypeId } = this;
      const index = _.findIndex(
        freePlaceTypes,
        type => type.id === selectedTypeId,
      );
      return freePlaceTypes[index];
    },
    availableCorrection() {
      const { groupCorrection } = this;
      if (!groupCorrection) {
        return false;
      }
      return [1, 4, 5].indexOf(groupCorrection.value) === -1;
    },
    availableSanatorium() {
      const { groupCorrection } = this;
      if (!groupCorrection) {
        return false;
      }
      return [1, 2, 3].indexOf(groupCorrection.value) === -1;
    },
    groupCorrection() {
      const group = this.selectedGroup;
      if (!group) {
        return null;
      }
      // noinspection JSUnresolvedVariable
      const groupCorrectionType = group.correction_type;
      const { dataConfig: { correctionTypes } } = this;
      const found = _.findIndex(
        correctionTypes,
        item => parseInt(item.value, 10)
          === parseInt(groupCorrectionType, 10),
      );
      // noinspection JSUnresolvedVariable
      return this.dataConfig.correctionTypes[found] || null;
    },
  },
  methods: {
    setSelectedGroup(value) {
      this.hasCorrection = false;
      this.hasSanatorium = false;
      this.disease = null;
      this.sanatoriumType = null;
      if (this.validation.group_id !== undefined) {
        this.validation.group_id = [];
      }
      if (value === null) {
        this.selectedGroupId = null;
        return;
      }

      this.setAgePlaceCount(value);

      this.selectedGroupId = parseInt(value.id, 10);

      const { apiProvider, dataConfig: { freePlaceTypes } } = this;

      if (apiProvider) {
        // noinspection JSIgnoredPromiseFromCall
        apiProvider.provideGetGroupLanguageByGroupId()
          .apply(this);
      }
      if (this.groupCorrection && this.groupCorrection.value !== 1) {
        if ([2, 3].indexOf(this.groupCorrection.value) !== -1) {
          this.hasCorrection = true;
          if (
            typeof this.selectedGroup.diseases === 'undefined'
            || !this.selectedGroup.diseases.length
          ) {
            this.$notify({
              type: 'warn',
              title: this.trans('notice.message'),
              text: this.trans('warning_text.no_group_diseases_available'),
            });
          }
        }
        if ([4, 5].indexOf(this.groupCorrection.value) !== -1) {
          this.hasSanatorium = true;
          if (
            typeof this.selectedGroup.sanatorium === 'undefined'
            || !this.selectedGroup.sanatorium.length
          ) {
            this.$notify({
              type: 'warn',
              title: this.trans('notice.message'),
              text: this.trans('warning_text.no_group_sanatorium_available'),
            });
          }
        }
        const index = _.findIndex(freePlaceTypes, item => item.id === 1);
        this.setSelectedType(freePlaceTypes[index], true);
      }

      if (value.diseases && value.diseases.length) {
        this.disease = value.diseases[0].id;
      }
      if (this.freePlace && this.freePlace.disease) {
        this.disease = this.freePlace.disease;
      }

      if (value.sanatorium && value.sanatorium.length) {
        this.sanatoriumType = value.sanatorium[0].id;
      }
      if (this.freePlace && this.freePlace.sanatorium) {
        this.sanatoriumType = this.freePlace.sanatorium.id;
      }
    },
    setAgePlaceCount(value, fromFreePlace = false) {
      const agePlaceCount = {};
      if (!fromFreePlace) {
        this.$set(this, 'agePlaceCount', {});
        if (value.multiplePlaceCount) {
          _.forEach(value.ages, (age) => {
            agePlaceCount[age.id] = null;
          });
          this.$set(this, 'agePlaceCount', agePlaceCount);
        }
        return;
      }
      _.forEach(value, (freePlaceAge) => {
        agePlaceCount[freePlaceAge.year_id] = freePlaceAge.place_count ?? null;
      });
      this.$set(this, 'agePlaceCount', agePlaceCount);
    },
    setSelectedType(value, force = false) {
      if ((this.hasCorrOrgQueue && (this.correction || this.sanatorium)) && !force) {
        return;
      }
      if (value === null) {
        this.selectedTypeId = null;
        return;
      }
      this.selectedTypeId = parseInt(value.id, 10);
      if (this.selectedTypeId !== TEMPORARY_FREE_PLACE_TYPE) {
        this.dateEnd = null;
      }
      if (
        this.isPrivate !== undefined
        && this.isPrivate
        && this.selectedTypeId === EARLY_FREE_PLACE_TYPE
      ) {
        this.budget = true;
      }
      if (this.validation) {
        if (this.validation.type !== undefined) {
          this.validation.type = [];
        }
      }
    },
    setDateActive(value) {
      this.dateActive = value;
    },
    prepareSendData() {
      const {
        // Data
        // eslint-disable-next-line camelcase
        selectedTypeId: type, selectedGroupId: group_id,
        // eslint-disable-next-line camelcase
        dateStart: date_start, dateActive: date_active,
        // eslint-disable-next-line camelcase
        dateEnd: date_end, placeCount: count,
        selectedGroup: { edu_lang: lang },
        correction,
        disease,
        sanatorium,
        sanatoriumType,
        budget,
        // Control
        isPrivate,
        agePlaceCount,
      } = this;


      let data = {
        group_id,
        type,
        date_active,
        date_end,
        count,
        lang,
        correction,
        is_sanatorium: sanatorium,
        disease,
        sanatorium: sanatoriumType,
        agePlaceCount,
      };

      data = type === COMMON_FREE_PLACE_TYPE
        ? {
          ...data,
          date_start: date_active,
        }
        : {
          ...data,
          date_start,
        };

      data = isPrivate
        ? {
          ...data,
          budget: budget ? 1 : 0,
        }
        : data;

      return data;
    },
    goBack() {
      const { apiConfig } = this;

      if (apiConfig && apiConfig.freePlace.backButtonUrl !== undefined) {
        window.location.href = apiConfig.freePlace.backButtonUrl;
      }
    },
  },
};


export default Fields;
