import Vue from 'vue';

import { stopAndPrevent } from '../../utils/event';
import slot from '../../utils/slot';

export default Vue.extend({
  name: 'QRadio',

  props: {
    value: {
      required: true,
    },
    val: {
      required: true,
    },
    required: Boolean,

    label: String,
    leftLabel: Boolean,

    color: String,
    keepColor: Boolean,
    dark: Boolean,
    dense: Boolean,

    disable: Boolean,
    tabindex: [String, Number],

    name: {
      type: String,
      default: '',
    },
  },

  computed: {
    isTrue() {
      return this.value === this.val;
    },

    classes() {
      return {
        'sn--disabled': this.disable,
        disabled: this.disable,
        'sn-radio--dark': this.dark,
        'sn-radio--dense': this.dense,
        'sn--cursor-pointer': !this.disable,
        reverse: this.leftLabel,
      };
    },

    innerClass() {
      if (this.isTrue === true) {
        return `sn-radio__inner--active${
          this.color !== void 0 ? ` s-c-${this.color}` : ''}`;
      } if (this.keepColor === true && this.color !== void 0) {
        return `s-c-${this.color}`;
      }
    },

    computedTabindex() {
      return this.disable === true ? -1 : this.tabindex || 0;
    },
  },

  methods: {
    set(e) {
      e !== void 0 && stopAndPrevent(e);
      if (this.disable !== true && this.isTrue !== true) {
        this.$emit('input', this.val);
      }
    },

    __keyDown(e) {
      if (e.keyCode === 13 || e.keyCode === 32) {
        this.set(e);
      }
    },
  },

  render(h) {
    return h('div', {
      staticClass: 'sn-radio sn--no-outline sn--row sn--inline sn--no-wrap',
      class: this.classes,
      attrs: { tabindex: this.computedTabindex },
      on: {
        click: this.set,
        keydown: this.__keyDown,
      },
    }, [
      h('div', {
        staticClass: 'sn-radio__inner s-pos-relative-position',
        class: this.innerClass,
      }, [
        this.disable !== true
          ? h('input', {
            staticClass: 'sn-radio__native sn-ma-none sn-pa-none sn--invisible',
            attrs: { type: 'checkbox', name: this.name },
            on: { change: this.set },
          })
          : null,

        h('div', {
          staticClass: 'sn-radio__bg s-pos-absolute',
        }, [
          h('div', { staticClass: 'sn-radio__outer-circle s-pos-absolute-full' }),
          h('div', { staticClass: 'sn-radio__inner-circle s-pos-absolute-full' }),
        ]),
      ]),

      this.label !== void 0 || this.$scopedSlots.default !== void 0
        ? h('div', {
          staticClass: 'sn-radio__label sn-anchor--skip',
        }, (this.label !== void 0 ? [this.label] : []).concat(slot(this, 'default')))
        : null,
    ]);
  },
});
