import Vue from 'vue';
import store from '@app_balabaqsha/store';
import componentsForm from '@common/components/Form';
import components from '@app_balabaqsha/components';
import bootstrap from './bootstrap';

bootstrap(Vue);
componentsForm(Vue);
components(Vue);

[
  'v-app',
  'v-app-menu',
  'v-app-header',
  'v-app-mobile-menu',
  'v-app-header-menu',
  'v-app-main-page',
  'v-app-insta',
  'v-app-footer',
]
  .forEach((element) => {
    if (document.getElementById(element)) {
      const config = {};
      config.store = store;
      const vm = new Vue(config);
      vm.$mount(`#${element}`);
    }
  });
