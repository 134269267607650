import VStatementQuestionnaire from '@app_balabaqsha/components/components/VStatementQuestionnaire';
import VRegularStatementCreateView
from '@app_balabaqsha/components/views/VRegularStatementCreateView';
import { scrollTop } from '@vjs/helpers';
import VSteps from './VSteps';
import VStepsBody from './VStepsBody';
import VStepInformation from './VStepInformation';
import VStepAuthentication from './VStepAuthentication';
import VStepUserinfo from './VStepUserinfo';
import VStepAddChild from './VStepAddChild';
import VStepAddStatement from './VStepAddStatement';
import VStepCongratulations from './VStepCongratulations';

export default {
  name: 'VStatementCreateView',
  components: {
    VRegularStatementCreateView,
    VStatementQuestionnaire,
    VSteps,
    VStepsBody,
    VStepInformation,
    VStepAuthentication,
    VStepUserinfo,
    VStepAddChild,
    VStepAddStatement,
    VStepCongratulations,
  },
  props: {
    uriStatic: {
      type: String,
      default: '',
    },
    uriAbout: {
      type: String,
      required: true,
    },
    // Registration
    uriSendPhone: {
      type: String,
      required: true,
    },
    uriSendCode: {
      type: String,
      required: true,
    },
    uriSendPassword: {
      type: String,
      required: true,
    },
    // Authorization
    uriSendAuth: {
      type: String,
      required: true,
    },
    uriGetProfile: {
      type: String,
      required: true,
    },
    uriSendProfileUpdate: {
      type: String,
      required: true,
    },
    // Password restore
    uriSendRestorePhone: {
      type: String,
      required: true,
    },
    uriSendRestoreCode: {
      type: String,
      required: true,
    },
    uriSendRestorePassword: {
      type: String,
      required: true,
    },
    // Request userinfo by iin
    uriSendIin: {
      type: String,
      required: true,
    },
    // Запросы по добавлению ребёнка
    uriSendChildIin: {
      type: String,
      required: true,
    },
    uriSendChild: {
      type: String,
      required: true,
    },
    uriSendStatement: {
      type: String,
      required: true,
    },
    uriSendStatementDisease: {
      type: String,
      required: true,
    },
    uriSendStatementSanatorium: {
      type: String,
      required: true,
    },
    // Request select options for nationalities
    uriNationalities: {
      type: String,
      required: true,
    },
    referenceTime: Number,
    // Authorization status
    auth: {
      type: Boolean,
      required: true,
    },
    uriGetStatementFormData: {
      type: String,
      required: true,
    },
    linkStatements: {
      type: String,
      default: '',
    },
    uriOfferModal: {
      type: String,
      required: true,
    },
    uriGetLgotTypeDocuments: {
      type: String,
      required: true,
    },
    uriGetLgotTypes: {
      type: String,
      required: true,
    },
    uriCheckBenefits: {
      type: String,
      required: true,
    },
    uriCheckBenefitsNedb: {
      type: String,
      required: true,
    },
    uriCheckSpecialType: {
      type: String,
    },
    uriSchoolsInMap: {
      type: String,
    },
    uriGroupTypes: {
      type: String,
      default: '/api/v1/references/statement/group-types',
    },
    diseaseTypes: {
      type: Array,
      required: true,
    },
    sanatoriumTypes: {
      type: Array,
      required: true,
    },
    queueTypes: {
      type: Array,
      required: true,
    },
    nedbIntegration: {
      type: Boolean,
      default: false,
    },
    questionnaire: {
      type: Object,
      default: {
        enabled: false,
        getDataUrl: null,
        getSchoolsUrl: null,
        submitUrl: null,
        parentInfo: {
          iin: null,
          phone: null,
          mail: null,
        },
      },
    },
    isVoucherStatement: {
      type: Boolean,
      default: false,
    },
    codeSize: {
      type: [Number, String],
      default: 4,
    },
    uriLocalities: {
      type: String,
      default: 'api/v1/active-localities',
    },
  },
  data() {
    return {
      loading: false,
      authData: {},
      userInfo: {
        parentId: 0,
        childId: 0,
        statusOptions: [{ value: null }],
      },
      questionnaireFinish: false,
      statementInfo: {
        type: null,
        localityId: null,
        questionnaire: false,
      },
    };
  },

  beforeCreate() {
    this.$trans.add(['statement']);
  },

  mounted() {
    if (this.auth) {
      this.$store.commit('SET_VISIBLE_STEP', {
        step: 2,
        visible: false,
      });
    }
  },

  computed: {
    current() {
      return this.$store.state.vSteps.currentStep;
    },
  },

  watch: {
    current() {
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.stepsBody.$el.getBoundingClientRect().top),
        500,
      );
    },
  },

  methods: {
    loadingProcessing(status) {
      this.loading = status;
    },
    sendData(data) {
      this.authData = data;
    },
    sendUserInfo(data) {
      this.userInfo = data;
    },
    setQuestionnaire(data) {
      this.questionnaireFinish = data;
      this.$store.commit('SET_CURRENT_STEP', 6);
    },
  },
};
