import _ from 'lodash';
// noinspection NpmUsedModulesInstalled
import VNoResults from '@vjs/components/VNoResults';
// noinspection NpmUsedModulesInstalled
import { requestWrapper } from '@vjs/helpers';
// noinspection NpmUsedModulesInstalled
import {
  QBtn,
  QIcon,
  QInnerLoading,
  QInput,
  QSelect,
  QTable,
  QTd,
  QTh,
  QTooltip,
  QTr,
} from '@quasar/components';
// noinspection NpmUsedModulesInstalled
import { Confirm } from '@app_balabaqsha/components/views/VContingent/Dialog';

// noinspection NpmUsedModulesInstalled
import { ATTENDANCE_ORGANIZATION_CONTROL } from '@app_balabaqsha/consts';
import { ERR_404 } from '@vjs/helpers/requestWrapper';


import Localization from '../Mixins/QuasarLocale';

export default {
  name: 'VAttendanceOrgList',
  components: {
    Confirm,
    QSelect,
    QIcon,
    QTable,
    QTh,
    QTr,
    QTd,
    QBtn,
    QInnerLoading,
    QTooltip,
    QInput,
    VNoResults,
  },
  mixins: [Localization],
  props: {
    uriGetData: {
      type: String,
    },
    uriLocality: {
      type: String,
    },
    uriSchoolConfig: {
      type: String,
    },
    description: {
      type: String,
    },
    currentYear: {
      type: Number,
      required: false,
    },
    currentMonth: {
      type: Object,
      required: false,
    },
    availableDates: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      filter: '',
      loading: false,
      localityOptions: [],
      localityOptionsFiltered: [],
      localitySelected: null,
      yearSelected: this.currentYear,
      monthSelected: this.currentMonth,
      configPopup: false,
      selectedSchoolConfig: null,
      configs: {},
      table: {
        pagination: {
          rowsPerPage: 20,
        },
        rows: [],
      },
      canEdit: true,
      voucherFunding: false,
    };
  },
  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'warning_text',
      'config',
    ]);

    await this.requestGetLocalities();
    await this.requestGetTable();
  },
  beforeMount() {
    this.initLocale();
  },
  beforeCreate() {
    this.$trans.add(ATTENDANCE_ORGANIZATION_CONTROL);
  },
  methods: {
    cancelConfig() {
      this.configPopup = false;
      this.selectedSchoolConfig = null;
      this.configs = {};
    },
    async confirmConfig(config, status) {
      const { $trans: { get: trans } } = this;
      this.configs[config] = status;
      const {
        selectedSchoolConfig: schoolId,
        uriSchoolConfig: uri,
        configs,
      } = this;

      const response = await this.$axios.post(`${uri}/${schoolId}`, {
        configs,
      });

      if (
        config === 'timesheet_extended_edit'
      ) {
        this.$notify({
          type: 'success',
          text: status
            ? trans('config.activated')
            : trans('config.deactivated'),
        });
      }

      if (response.data && response.data.status && response.data.status === 'ok') {
        this.configPopup = false;
        this.selectedSchoolConfig = null;
        this.configs = {};
        this.requestGetTable();
      }
    },
    selectConfig(schoolId, configs) {
      this.configs = configs || { timesheet_extended_edit: 0 };
      this.configPopup = true;
      this.selectedSchoolConfig = schoolId;
    },
    async requestGetLocalities() {
      const res = await requestWrapper({
        url: this.uriLocality,
      });
      if (res.error) {
        if (res.errorType === ERR_404 && _.isBoolean(res.data.region)) {
          this.modalRegionSelect = true;
        }
        return;
      }
      this.localityOptions = res.data;
      this.localityOptionsFiltered = res.data;
      this.localitySelected = res.data[0].value;
    },
    async requestGetTable() {
      this.loading = true;
      const params = {
        locality_id: this.localitySelected,
      };
      if (this.availableDates) {
        params.date = `${this.yearSelected}-${this.monthSelected.value}`;
      }

      const res = await requestWrapper({
        url: this.uriGetData,
        params,
      });

      if (!res.errors) {
        this.table.rows = res.data.rows;
        this.canEdit = res.data.canEditConfig;
        this.voucherFunding = res.data.voucherFunding;
      }
      this.loading = false;
    },
    filterFn(val, update) {
      if (val === '') {
        update(() => {
          this.localityOptions = this.localityOptionsFiltered;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.localityOptions = this.localityOptionsFiltered.filter(item => _.lowerCase(item.label)
          .indexOf(needle) > -1);
      });
    },
    getCellClass(row, name) {
      switch (name) {
        case 'groups_count':
          return 's-to-center';
        case 'status':
          return `s-c-${row.color} s-to-center`;
        default:
          return '';
      }
    },
    getCellClassAdaptive(row, name) {
      switch (name) {
        case 'status':
          return `s-c-${row.color}`;
        default:
          return '';
      }
    },
  },
  computed: {
    yearOptions() {
      const { availableDates } = this;
      if (!availableDates) {
        return [];
      }
      return Object.keys(availableDates);
    },
    monthOptions() {
      const {
        availableDates,
        currentMonth,
      } = this;

      if (!availableDates) {
        return [];
      }

      return typeof availableDates[this.yearSelected] !== 'undefined'
        ? availableDates[this.yearSelected]
        : [currentMonth];
    },
    tableColumns() {
      const columns = [
        {
          name: 'name',
          required: true,
          label: this.trans('label.name'),
          align: 'left',
          field: 'name',
          sortable: false,
        },
        {
          name: 'groups_count',
          required: true,
          label: this.trans('label.groups_count'),
          align: 'center',
          field: 'groups_count',
          sortable: false,
          width: 200,
        },
        {
          name: 'actions',
          required: false,
          label: this.trans('label.operations'),
          align: 'center',
          field: 'actions',
          sortable: false,
          width: 175,
        },
      ];

      if (this.voucherFunding) {
        columns.splice(2, 0, {
          name: 'status',
          required: true,
          label: this.trans('label.status'),
          align: 'center',
          field: 'status',
          sortable: false,
          width: 200,
        });
      }

      return columns;
    },
  },
};
