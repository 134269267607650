import VOrganizationInfo from '@common/views/VOrganizationInfo';
import VOrganizationProfile from '@common/views/VOrganizationProfile';
import NewTableWithFilters from '@common/views/NewTableWithFilters';
import VAdBanners from '@common/components/VAdBanners';
import VLoginForm from '@common/views/VLoginForm';
import FormRegistrationSms from '@common/views/FormRegistrationSms';
import FormResetPassword from '@common/views/FormResetPassword';
import VOrganizationReports from '@common/views/VOrganizationReports';
import VPasswordChange from '@vjs/views/VPasswordChange/VPasswordChange.vue';
import VUserProfileUpdate from '@vjs/views/VUserProfileUpdate/VUserProfileUpdate.vue';
import VProfileSettings from '@vjs/views/VProfileSettings/VProfileSettings.vue';
import FormLogin from '@vjs/views/FormLogin/FormLogin.vue';
import FormPersonnelCreate from '@vjs/views/FormPersonnelCreate/FormPersonnelCreate.vue';
import VFaqSpoiler from '@vjs/views/VFaqSpoiler/VFaqSpoiler.vue';
import VFaq from '@vjs/views/VFaq/VFaq.vue';
import Modal from '@vjs/plugins/ModalWindow/ModalWindow.vue';
import VDocAttach from '@vjs/views/VDocAttach/VDocAttach.vue';
import VStatisticPanel from '@vjs/components/VStatisticPanel';
import VReferenceTime from '@vjs/components/VReferenceTime';
import VInsta from '@vjs/views/VInsta/VInsta.vue';
import VFeedbackForm from '@vjs/views/VFeedbackForm';
import VFeedbacksList from '@vjs/views/VFeedbacksList';
import VSupportTeamButton from '@vjs/components/VSupportTeamButton';

import VSubheader from '@vjs/components/Text/VSubheader.vue';
import MobileMenu from '@vjs/components/MobileMenu/MobileMenu.vue';
import VTable from '@vjs/components/VTable/VTable.vue';
import VMenu from '@vjs/components/VMenu/VMenu.vue';
import FormPhoto from '@vjs/components/FormPhoto';

export default function (Vue) {
  Vue.component('VOrganizationInfo', VOrganizationInfo);
  Vue.component('VOrganizationProfile', VOrganizationProfile);
  Vue.component('NewTableWithFilters', NewTableWithFilters);
  Vue.component('VAdBanners', VAdBanners);
  Vue.component('VLoginForm', VLoginForm);
  Vue.component('FormRegistrationSms', FormRegistrationSms);
  Vue.component('FormResetPassword', FormResetPassword);
  Vue.component('VOrganizationReports', VOrganizationReports);
  Vue.component('VPasswordChange', VPasswordChange);
  Vue.component('VUserProfileUpdate', VUserProfileUpdate);
  Vue.component('VProfileSettings', VProfileSettings);
  Vue.component('FormLogin', FormLogin);
  Vue.component('FormPersonnelCreate', FormPersonnelCreate);
  Vue.component('VFaqSpoiler', VFaqSpoiler);
  Vue.component('VFaq', VFaq);
  Vue.component('Modal', Modal);
  Vue.component('VDocAttach', VDocAttach);
  Vue.component('VStatisticPanel', VStatisticPanel);
  Vue.component('VReferenceTime', VReferenceTime);
  Vue.component('VInsta', VInsta);
  Vue.component('VFeedbackForm', VFeedbackForm);
  Vue.component('VFeedbacksList', VFeedbacksList);
  Vue.component('VSupportTeamButton', VSupportTeamButton);

  Vue.component('VSubheader', VSubheader);
  Vue.component('MobileMenu', MobileMenu);
  Vue.component('VTable', VTable);
  Vue.component('VMenu', VMenu);
  Vue.component('FormPhoto', FormPhoto);
}
