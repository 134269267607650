import _ from 'lodash';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QInput,
  QDate,
  QPopupProxy,
  QSelect,
  QTable,
  QIcon,
  QTr,
  QTh,
  QTd,
  QBtn,
  QTooltip,
} from '@quasar/components';
import axios from 'axios';
import { ERR_404 } from '@vjs/helpers/requestWrapper';


export default {
  name: 'VEnrolledChildrensByAgeMonitoringView',
  components: {
    QInput,
    QDate,
    QPopupProxy,
    QSelect,
    QTable,
    QIcon,
    QTr,
    QTh,
    QTd,
    QBtn,
    QTooltip,
  },
  props: {
    apiUrl: {
      type: String,
    },
    uriLocality: {
      type: String,
    },
    sourceTypes: {
      type: Object,
      default: () => ({
        all: 'all',
        portal: 'portal',
        import: 'import',
      }),
    },
    withExport: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    form: {
      locality: 0,
      source: '',
      page: 1,
    },
    exportLoading: false,
    table: {
      inFullscreen: false,
      pagination: {
        rowsPerPage: 10,
      },
      columns: [],
      title: '',
    },
    filters: {
      date: {
        from: '',
        to: '',
      },
    },
    rows: [],
    rowNames: [],
    totalNames: [],
    filterString: '',
    localityOptions: [],
    localityOptionsRaw: [],
    sourcesOptions: [],
  }),
  async mounted() {
    await this.$trans.add(['statistics', 'button', 'menu', 'placeholder', 'label', 'main']);
    await this.requestGetLocalities();
    await this.addAllFilterField();
    await this.requestGetData();
  },
  beforeCreate() {
  },
  methods: {
    getCellStyle(cellName) {
      switch (cellName) {
        case 'count_of_childs_enrolled_in_state':
          return {
            whiteSpace: 'unset',
            minWidth: '25rem',
          };
        case 'count_of_childs_enrolled_in_private':
          return {
            whiteSpace: 'unset',
            minWidth: '25rem',
          };
        default:
          return {};
      }
    },
    addAllFilterField() {
      this.localityOptionsRaw.unshift({
        label: this.trans('main.all'),
        value: 0,
      });
      this.sourcesOptions.unshift({
        label: this.trans('label.all_sources'),
        value: '',
      });
    },
    async requestGetLocalities() {
      const localityRes = await requestWrapper({
        url: this.uriLocality,
      });
      if (localityRes.error) {
        if (localityRes.errorType === ERR_404 && _.isBoolean(localityRes.data.region)) {
          this.modalRegionSelect = true;
        }
        return;
      }
      this.localityOptions = localityRes.data;
      this.localityOptionsRaw = localityRes.data;
    },
    filtersFn(val, update) {
      if (val === '') {
        update(() => {
          this.localityOptions = this.localityOptionsRaw;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.localityOptions = this.localityOptionsRaw.filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
      });
    },
    sum(col) {
      const rows = _.filter(
        this.rows,
        item => typeof item[col.name] === 'number',
      );
      return _.sumBy(rows, col.name);
    },
    clearFilters() {
      this.form.locality = '';
      this.form.source = null;
      this.filters.date.to = '';
      this.filters.date.from = '';
    },

    async requestGetData() {
      this.$globalLoading.show();
      const dataRes = await requestWrapper({
        url: this.apiUrl,
        params: {
          locality: this.form.locality,
          source: this.form.source,
          to: this.filters.date.to,
          from: this.filters.date.from,
        },
      });

      if (!dataRes.error) {
        this.rows = dataRes.data.rows;
        this.rowNames = dataRes.data.rowNames;
        this.table.columns = dataRes.data.cols;
        this.totalNames = dataRes.data.totalNames;
      }
      this.$globalLoading.hide();
    },
    async exportu(e) {
      this.$globalLoading.show();
      this.loading = true;
      axios({
        url: `${this.apiUrl}?export=1`,
        method: 'GET',
        responseType: 'blob',
        params: {
          locality: this.form.locality,
          source: this.form.source,
          to: this.filters.date.to,
          from: this.filters.date.from,
        },
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } ${this.$trans.get('menu.monitoring_new.enrolled_childrens_by_age')
          } ${this.form.locality ? this.$trans.get('statistics.enrolled_childrens.locality', { date: this.form.locality }) : ''
          } ${this.form.source ? this.$trans.get('label.select_source', { date: this.form.source }) : ''
          } ${this.filters.date.from ? this.$trans.get('statistics.file.from', { date: this.filters.date.from }) : ''
          } ${this.filters.date.to ? this.$trans.get('statistics.file.to', { date: this.filters.date.to }) : ''
          } .xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.$globalLoading.hide();
        this.loading = false;
      });
    },
  },
  watch: {
    'filters.date.from': function () {
      this.requestGetData();
    },
    'filters.date.to': function () {
      this.requestGetData();
    },
  },
};
