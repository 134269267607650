// TODO: нужно СРОЧНО переписать бэк под эту штуку.


import axios from 'axios';
import _ from 'lodash';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QSelect,
  QTable,
  QIcon,
  QBtn,
  QTr,
  QTh,
  QTd,
  QTooltip,
  QInput,
} from '@quasar/components';
import { ERR_404 } from '@vjs/helpers/requestWrapper';


export default {
  name: 'VWorkerContingentListView',
  components: {
    QSelect, QTable, QIcon, QBtn, QTr, QTh, QTd, QTooltip, QInput,
  },
  props: {
    apiUrl: {
      type: String,
    },
    uriLocality: {
      type: String,
    },
    uriExport: {
      type: String,
    },
    uriFilters: {
      type: String,
    },
    uriGetOrganizations: {
      type: String,
    },
  },
  data: () => ({
    form: {
      organizationType: null,
      source: null,
      locality: null,
      organization: null,
      page: 1,
    },
    table: {
      inFullscreen: false,
      pagination: {
        rowsPerPage: 50,
      },
      columns: [],
    },
    metaData: {},
    statements: [],
    statistic: {},
    bystatus: {},
    localityOptions: [],
    orgNameOptions: [],
    sourcesOptions: [],
    organizationTypesOptions: [],
    localityOptionsRaw: [],
    loading: false,
    filter: '',
  }),
  async mounted() {
    await this.$trans.add([
      'bulletin',
      'statistics',
      'label',
      'menu',
      'main',
      'button',
      'queue',
      'placeholder',
    ]);
    this.requestGetLocalities();
    this.requestGetFilters();

    this.onRequest(this.table);

    this.table.columns = [
      {
        name: 'n',
        required: true,
        label: this.trans('statistics.worker_contingent.n'),
        align: 'left',
        field: 'n',
        sortable: true,
      },
      {
        name: 'l',
        required: true,
        label: this.trans('statistics.worker_contingent.l'),
        align: 'left',
        field: 'l',
        sortable: true,
      },
      {
        name: 'o',
        required: true,
        label: this.trans('statistics.worker_contingent.o'),
        align: 'left',
        field: 'o',
        sortable: true,
      }, {
        name: 'of',
        required: true,
        label: this.trans('statistics.worker_contingent.of'),
        align: 'left',
        field: 'of',
        sortable: true,
      }, {
        name: 'pr-gov',
        required: true,
        label: this.trans('statistics.worker_contingent.pr-gov'),
        align: 'left',
        field: 'pr-gov',
        sortable: true,
      }, {
        name: 'ic',
        required: true,
        label: this.trans('statistics.worker_contingent.ic'),
        align: 'left',
        field: 'ic',
        sortable: true,
      }, {
        name: 'f',
        required: true,
        label: this.trans('statistics.worker_contingent.f'),
        align: 'left',
        field: 'f',
        sortable: true,
      }, {
        name: 'd',
        required: true,
        label: this.trans('statistics.worker_contingent.d'),
        align: 'left',
        field: 'd',
        sortable: true,
      }, {
        name: 'g',
        required: true,
        label: this.trans('statistics.worker_contingent.g'),
        align: 'left',
        field: 'g',
        sortable: true,
      }, {
        name: 'ac',
        required: true,
        label: this.trans('statistics.worker_contingent.ac'),
        align: 'left',
        field: 'ac',
        sortable: true,
      }, {
        name: 'ad',
        required: true,
        label: this.trans('statistics.worker_contingent.ad'),
        align: 'left',
        field: 'ad',
        sortable: true,
      }, {
        name: 's',
        required: true,
        label: this.trans('statistics.worker_contingent.s'),
        align: 'left',
        field: 's',
        sortable: true,
      }, {
        name: 'sf',
        required: true,
        label: this.trans('statistics.worker_contingent.sf'),
        align: 'left',
        field: 'sf',
        sortable: false,
      }, {
        name: 'df',
        required: true,
        label: this.trans('statistics.worker_contingent.df'),
        align: 'left',
        field: 'sf',
        sortable: false,
      }, {
        name: 'cf',
        required: true,
        label: this.trans('statistics.worker_contingent.cf'),
        align: 'left',
        field: 'sf',
        sortable: false,
      },
    ];
  },
  methods: {
    async requestOrg() {
      this.onRequest(this.table);
      const orgRes = await requestWrapper.call(this, {
        url: this.uriGetOrganizations,
        params: {
          locality: this.form.locality,
        },
      });
      this.orgNameOptions = (_.isArray(orgRes.data) && orgRes.data) || [];

      if (this.organization && this.organization.value && this.orgNameOptions.length) {
        this.organization = { value: null };
      }
    },
    async requestGetLocalities() {
      const localityRes = await requestWrapper({
        url: this.uriLocality,
      });
      if (localityRes.error) {
        if (localityRes.errorType === ERR_404 && _.isBoolean(localityRes.data.region)) {
          this.modalRegionSelect = true;
        }
        return;
      }
      this.localityOptions = localityRes.data;
      this.localityOptionsRaw = localityRes.data;
    },
    async requestGetFilters() {
      const statusRes = await requestWrapper({
        url: this.uriFilters,
      });
      this.sourcesOptions = statusRes.data.sources;
      this.organizationTypesOptions = statusRes.data.organizationTypes;
    },
    filterFn(val, update) {
      if (val === '') {
        update(() => {
          this.localityOptions = this.localityOptionsRaw;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.localityOptions = this.localityOptionsRaw.filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
      });
    },
    clearLocality() {
      this.form.organization = '';
      this.orgNameOptions = [];
      this.form.locality = '';
      this.submit();
    },
    async submit() {
      this.onRequest(this.table);
    },
    async exportu(e) {
      this.loading = true;
      axios({
        url: this.uriExport,
        method: 'POST',
        data: this.form,
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } ${this.$trans.get('menu.worker_contingent')
          } .xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loading = false;
      });
    },
    serialize(form) {
      const serialized = [];
      Object.keys(form).forEach((key) => {
        serialized.push(`${encodeURIComponent(key)}=${encodeURIComponent(form[key])}`);
      });
      return serialized.join('&');
    },
    async onRequest(props) {
      const {
        page, rowsPerPage, sortBy, descending,
      } = props.pagination;

      this.loading = true;

      this.form.page = page;

      const res = await requestWrapper({
        method: 'post',
        url: this.apiUrl,
        data: this.form,
      });

      this.statements = res.data;
      this.metaData = res.meta;
      // this.table.pagination.rowsNumber = this.metaData.total;
      // this.table.pagination.page = page;
      // this.table.pagination.rowsPerPage = rowsPerPage;
      // this.table.pagination.sortBy = sortBy;
      // this.table.pagination.descending = descending;
      this.loading = false;
    },
  },
};
