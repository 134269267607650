import { Helper } from '@common/src/helpers';
import Validation from '@vjs/mixins/Validation';

export default {
  mixins: [Validation],
  props: {
    checkIinParent: '',
    submit: '',
    informConfig: {
      type: Object,
      default: () => ({
        message: '',
        color: 'red',
      }),
    },
  },
  data() {
    return {
      form: {
        iin: null,
        fullName: null,
        iinFilled: false,
      },
    };
  },
  beforeCreate() {
    this.$trans.add([
      'text',
      'button',
      'placeholder',
      'validation',
    ]);
  },

  methods: {
    clearForm() {
      if (this.form.iinFilled) {
        this.form.iin = null;
        this.form.fullName = null;
        this.form.iinFilled = false;
      }
    },
    handleCancel() {
      if (this.form.iinFilled) {
        this.clearForm();
      } else {
        this.$emit('onClose');
      }
    },
    // eslint-disable-next-line consistent-return
    async checkIin(iin) {
      if (!this.validateIin(iin)) {
        return false;
      }

      this.$globalLoading.show();

      const url = this.checkIinParent;
      const { statementId } = this;
      const data = { iin, statementId };

      const res = await requestWrapper.call(this, {
        url,
        method: 'post',
        data,
      });

      if (!res.error) {
        this.form.iinFilled = true;
        this.form.fullName = res.fullName;
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
    async request() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.submit,
        method: 'post',
        data: this.form,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
