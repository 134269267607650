import RefreshRepository
from '@app_balabaqsha/repositories/RefreshRepository';
import TimeSheetRepository
from '@app_balabaqsha/repositories/attendance/TimeSheetRepository';
import AttendanceGroupsApiProvider
from '@app_balabaqsha/providers/Attendance/AttendanceGroupsApiProvider';

const AttendanceListProvider = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider({ refreshApi, timeSheetGet }) {
      const trans = this.$trans.get;
      this.apiProvider = new AttendanceGroupsApiProvider({
        RefreshRepository: new RefreshRepository(refreshApi),
        TimeSheetRepository: new TimeSheetRepository(timeSheetGet),
      }, trans);
    },
  },
};

export default AttendanceListProvider;
