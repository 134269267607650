import axios from 'axios';
import { FALLBACK_LOCALE } from '@app_balabaqsha/consts';

export default class TimeSheetAttendanceMarkRepository {
  constructor({
    apiUrl,
    locale,
  }) {
    this.url = apiUrl || '';
    this.locale = locale || FALLBACK_LOCALE;
  }

  async attendanceMark(contingentId, status, date) {
    const { url, locale } = this;
    return await axios.post(
      `${url}/${contingentId}`,
      { status, date, isAlt: true },
      {
        headers: {
          'X-localization': locale,
        },
      },
    );
  }
}
