import axios from 'axios';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QSelect,
  QTable,
  QIcon,
  QTr,
  QTh,
  QTd,
  QBtn,
  QInput,
  QTooltip,
} from '@quasar/components';
import _ from 'lodash';


export default {
  name: 'VPersonnelMonitoringView',
  components: {
    QSelect, QTable, QIcon, QTr, QTh, QTd, QBtn, QInput, QTooltip,
  },
  props: {
    apiUrl: {
      type: String,
    },
    withExport: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    table: {
      inFullscreen: false,
      pagination: {
        rowsPerPage: 10,
      },
      columns: [],
      colNames: [
        { name: 'org_gov', label: 'org_gov' },
        { name: 'org_private', label: 'org_private' },
        { name: 'org_total', label: 'org_total' },
        { name: 'pers_gov', label: 'pers_gov' },
        { name: 'pers_priv', label: 'pers_priv' },
        { name: 'perso_total', label: 'perso_total' },
      ],
      title: '',
    },
    rows: [],
    loading: false,
    filter: '',
  }),
  async mounted() {
    this.requestGetData();

    this.table.columns = [
      {
        name: 'name',
        required: true,
        label: 'statistics.personnel.name',
        align: 'left',
        field: 'name',
        sortable: true,
      }, {
        name: 'org_gov',
        required: true,
        label: 'statistics.personnel.gov',
        align: 'left',
        field: 'org_gov',
        sortable: true,
      }, {
        name: 'org_private',
        required: true,
        label: 'statistics.personnel.priv',
        align: 'left',
        field: 'org_private',
        sortable: true,
      }, {
        name: 'org_total',
        required: true,
        label: 'statistics.personnel.total',
        align: 'left',
        field: 'org_total',
        sortable: true,
      }, {
        name: 'pers_gov',
        required: true,
        label: 'statistics.personnel.gov',
        align: 'left',
        field: 'pers_gov',
        sortable: true,
      }, {
        name: 'pers_priv',
        required: true,
        label: 'statistics.personnel.priv',
        align: 'left',
        field: 'pers_priv',
        sortable: true,
      }, {
        name: 'perso_total',
        required: true,
        label: 'statistics.personnel.total',
        align: 'left',
        field: 'perso_total',
        sortable: true,
      },
    ];
  },
  beforeCreate() {
    this.$trans.add(['statistics', 'button', 'menu', 'placeholder']);
  },
  methods: {
    async requestGetData() {
      const dataRes = await requestWrapper({
        url: this.apiUrl,
      });

      this.rows = dataRes.data;
    },
    sum(col) {
      return _.sumBy(this.rows, col.name);
    },
    async exportu(e) {
      this.loading = true;
      axios({
        url: `${this.apiUrl}?export=1`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } ${this.$trans.get('menu.monitoring_new.personnel')
          } .xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loading = false;
      });
    },
  },
};
