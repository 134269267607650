import _ from 'lodash';
import VFormInform from '@vjs/components/VFormInform';
import { requestWrapper } from '@vjs/helpers';
import { QBtn } from '@quasar/components';
import VConfirmDialog from '@vjs/modals/VConfirmDialog';

export default {
  components: {
    VFormInform,
    QBtn,
    VConfirmDialog,
  },
  props: {
    defaultValue: {
      type: [Number, Array, Object],
      required: false,
    },
    value: {},
    openModal: {
      type: Boolean,
      default: false,
      required: true,
    },
    urlFiles: {
      type: String,
      required: true,
    },
    urlFileStore: {
      type: String,
      required: true,
    },
    validateInform: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      processSend: false,
      files: [],
      selectFile: {
        id: null,
      },
      selectedFiles: [],
    };
  },

  async mounted() {
    this.$globalLoading.show();
    await this.$trans.add([
      'label',
      'button',
      'title',
      'notice',
      'warning_text',
      'main',
    ]);
    await this.loadFiles();
    this.$globalLoading.hide();

    const vm = this;
    window.addEventListener('keyup', (ev) => {
      if (ev.key === 'Escape') {
        vm.modalClose();
      }
      return true;
    });
  },

  watch: {
    openModal(newValue) {
      const vm = this;
      if (newValue) {
        this.handleDefaultValue();
        $('#modal-user-files').modal('show');
      } else {
        vm.$set(vm, 'selectedFiles', []);
        vm.$set(vm, 'selectFile', { id: null });
        $('#modal-user-files').modal('hide');
      }
    },
  },

  computed: {
    validate() {
      if (this.multiple) {
        return this.selectedFiles.length !== 0;
      }
      return this.selectFile && this.selectFile.id !== null;
    },
  },
  methods: {
    handleDefaultValue() {
      const value = _.cloneDeep(this.defaultValue);
      if (
        this.multiple && _.isArray(value)
        && !_.isEmpty(value) && !_.isEqual(value, this.selectedFiles)
      ) {
        this.selectedFiles = value;
      } else if (!this.multiple) {
        if (_.isObject(value) && !_.isEmpty(value)) {
          this.selectFile = value;
        }
        if (_.isNumber(value)) {
          this.selectFile = this.files.find(file => file.id === value);
        }
      }
    },
    async upload(file) {
      this.$globalLoading.show();
      const formData = new FormData();
      formData.append('file', file);
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.urlFileStore,
        data: formData,
      });
      if (res.error && res.data.errors) {
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
      if (!res.error) {
        const files = _.cloneDeep(this.files);
        const isFileExists = files.find(localFile => localFile.id === res.id);
        if (!isFileExists) {
          files.unshift(res);
        } else {
          this.$notify({
            text: this.trans('notice.file_already_exists'),
            type: 'error',
          });
        }
        this.files = files;
      }
      this.$globalLoading.hide();
    },
    async loadFiles() {
      const res = await requestWrapper.call(this, {
        url: this.urlFiles,
      });
      this.processSend = false;
      if (!res.error) {
        this.files = res;
      }
    },
    modalClose() {
      const vm = this;
      vm.$emit('select-close');
      this.selectFile = { id: null };
      this.selectedFiles = [];
    },
    onDblclickSelectFile(file) {
      const vm = this;
      if (this.multiple) {
        this.onClickMultipleFiles(file);
        vm.$emit('input', vm.selectedFiles);
        this.selectedFiles = [];
      } else {
        vm.$emit('input', file);
        this.selectFile = { id: null };
      }
    },
    onChangeSelectFile(file) {
      const vm = this;
      if (vm.selectFile.id === file.id) {
        vm.$set(vm, 'selectFile', { id: null });
      } else {
        vm.$set(vm, 'selectFile', _.cloneDeep(file));
      }
    },
    onClickMultipleFiles(file) {
      if (this.multipleFileSelectCheck(file.id)) {
        this.selectedFiles.splice(this.selectedFiles.indexOf(file.id), 1);
      } else {
        if (this.maxFiles === this.selectedFiles.length) {
          this.$notify({
            text: this.trans('notice.cannot_select_more_than', { count: this.maxFiles }),
            type: 'error',
          });
          return;
        }
        this.selectedFiles.push(file.id);
      }
    },
    multipleFileSelectCheck(id) {
      return this.selectedFiles.indexOf(id) !== -1;
    },
    onSaveSelect() {
      const vm = this;
      if (vm.selectFile.id !== null) {
        vm.$emit('input', vm.selectFile);
        this.selectFile = { id: null };
      }
      if (this.multiple && this.selectedFiles.length !== 0) {
        vm.$emit('input', vm.selectedFiles);
        this.selectedFiles = [];
      }
    },
    onClickInputFile() {
      const el = document.querySelector('#user-file-upload-input');
      if (el.onclick) {
        el.onclick();
      } else if (el.click) {
        el.click();
      }
    },
    onChangeInputFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = files[0];
      e.target.value = null;
      this.upload(file);
    },
  },
};
