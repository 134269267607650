import ContingentRepository from '@app_balabaqsha/repositories/ContingentRepository';
import ContingentApiProvider
from '@app_balabaqsha/providers/Contingent/ContingentApiProvider';

const Providers = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider(contingentApi) {
      const trans = this.$trans.get;
      this.apiProvider = new ContingentApiProvider({
        ContingentRepository: new ContingentRepository(contingentApi),
      }, trans);
    },
  },
};

export default Providers;
