import { sortDate } from '../../utils/sort';
import { isNumber, isDate } from '../../utils/is';
import moment from "moment";

export default {
  props: {
    sortMethod: {
      type: Function,
      default(data, sortBy, descending) {
        const col = this.columns.find(def => def.name === sortBy);
        if (col === null || col.field === void 0) {
          return data;
        }

        const
          dir = descending === true ? -1 : 1;
        const val = typeof col.field === 'function'
          ? v => col.field(v)
          : v => v[col.field]?.value ?? v[col.field];

        return data.sort((a, b) => {
          let
            A = val(a);
          let B = val(b);

          if (A === null || A === void 0) {
            return -1 * dir;
          }
          if (B === null || B === void 0) {
            return 1 * dir;
          }
          if (col.sort !== void 0) {
            return col.sort(A, B, a, b) * dir;
          }
          const numberA = this.parseLocaleNumber(A);
          const numberB = this.parseLocaleNumber(B);
          if (isNumber(numberA) === true && isNumber(numberB) === true) {
            return (numberA - numberB) * dir;
          }
          const dateA = this.parseDate(A);
          const dateB = this.parseDate(B);
          if (isDate(dateA) === true && isDate(dateB) === true) {
            return sortDate(dateA, dateB) * dir;
          }
          if (typeof A === 'boolean' && typeof B === 'boolean') {
            return (A - B) * dir;
          }

          [A, B] = [A, B].map(s => (`${s}`).toLocaleString().toLowerCase());

          return A < B
            ? -1 * dir
            : (A === B ? 0 : dir);
        });
      },
    },
  },

  computed: {
    columnToSort() {
      const { sortBy } = this.computedPagination;

      if (sortBy) {
        return this.columns.find(def => def.name === sortBy) || null;
      }
    },
  },

  methods: {
    parseDate(val) {
      const formats = ['DD.MM.YYYY']
      const md = moment(val, formats);
      return md?.isValid() ? md.toDate() : val;
    },
    parseLocaleNumber(val, locale = 'ru-RU') {
      if(isNumber(val)) {
        return val
      }
      const thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
      const decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

      return parseFloat(val
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.')
      );
    },
    sort(col /* String(col name) or Object(col definition) */) {
      if (col === Object(col)) {
        col = col.name;
      }

      let { sortBy, descending } = this.computedPagination;

      if (sortBy !== col) {
        sortBy = col;
        descending = false;
      } else if (this.binaryStateSort === true) {
        descending = !descending;
      } else if (descending === true) {
        sortBy = null;
      } else {
        descending = true;
      }

      this.setPagination({ sortBy, descending, page: 1 });
    },
  },
};
