<template>
  <q-form
    autocorrect="off"
    autocapitalize="off"
    autocomplete="off"
    spellcheck="false"
    @submit="approve"
  >
    <v-form-inform :message="trans('warning_text.voucher_application_revision_warn')" />

    <v-wrapper-q-select
      v-model="docs"
      :options="refs"
      multiple
      option-label="label"
      option-value="value"
      :extra-binds="{
        hideBottomSpace: true,
        label: trans('label.statement_files'),
        required: true,
      }"
    />

    <q-input
      v-model="message"
      :label="trans(`label.type_message`)"
      filled
      type="textarea"
      required
      counter
      :maxlength="1000"
    />

    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :disable="!message.length || !docs.length"
        type="submit"
        :label="trans('button.approve')"
      />
    </div>
  </q-form>
</template>

<script>
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },
    refs: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      message: '',
      docs: [],
    };
  },

  async mounted() {
    await this.$trans.add('warning_text');
  },
  methods: {
    async approve() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data: { message: this.message, docs: this.docs },
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
      } else {
        Helper.handlerResponseErrorNew(this, res);
        this.$globalLoading.hide();
      }
    },
  },
};
</script>
