import KindergartensGroupsRepository
from '@app_balabaqsha/repositories/KindergartensGroupsRepository';
import KindergartenGroupListApiProvider
from '@app_balabaqsha/providers/Kindergarten/KindergartenGroupListApiProvider';

const KindergartenListProviders = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider({ groups }) {
      const trans = this.$trans.get;
      this.apiProvider = new KindergartenGroupListApiProvider({
        KindergartensGroupsRepository: new KindergartensGroupsRepository(groups),
      }, trans);
    },
  },
};

export default KindergartenListProviders;
