import KindergartenLocalitiesRepository
from '@app_balabaqsha/repositories/KindergartenLocalitiesRepository';
import KindergartensRepository
from '@app_balabaqsha/repositories/KindergartensRepository';
import CalculateAllRepository
from '@app_balabaqsha/repositories/CalculateAllRepository';
import ConfirmCalculateRepository
from '@app_balabaqsha/repositories/ConfirmCalculateRepository';
import DenyCalculateRepository
from '@app_balabaqsha/repositories/DenyCalculateRepository';
import KindergartenListApiProvider
from '@app_balabaqsha/providers/Kindergarten/KindergartenListApiProvider';

const KindergartenListProviders = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider({
      localities,
      kindergartens,
      calculate,
      confirm,
      abort,
    }) {
      const trans = this.$trans.get;
      this.apiProvider = new KindergartenListApiProvider({
        ConfirmCalculateRepository: new ConfirmCalculateRepository(confirm),
        DenyCalculateRepository: new DenyCalculateRepository(abort),
        CalculateAllRepository: new CalculateAllRepository(calculate),
        KindergartenLocalitiesRepository: new KindergartenLocalitiesRepository(
          localities,
        ),
        KindergartensRepository: new KindergartensRepository(kindergartens),
      }, trans);
    },
  },
};

export default KindergartenListProviders;
