import LanguageRepository from '@app_balabaqsha/repositories/GroupLanguageRepository';
import FreePlaceRepository from '@app_balabaqsha/repositories/FreePlaceRepository';
import FormApiProvider from '@app_balabaqsha/providers/FreePlace/FormApiProvider';

const Providers = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider(freePlace, groupLanguage) {
      this.apiProvider = new FormApiProvider({
        freePlaceRepository: new FreePlaceRepository(freePlace),
        groupLanguageRepository: new LanguageRepository(groupLanguage),
      });
    },
  },
};

export default Providers;
