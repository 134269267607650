// noinspection NpmUsedModulesInstalled
import {
  ATTENDANCE_TRANSLATIONS,
} from '@vjs/balabaqsha/consts';

// noinspection NpmUsedModulesInstalled
import {
  QTable,
  QTh,
  QTr,
  QTd,
  QBtn,
  QSelect,
  QIcon,
} from '@quasar/components';

import axios from 'axios';
import Localization from '../Mixins/QuasarLocale';
import AttendanceListProvider from './Mixins/AttendanceListProvider';
import AttendanceExportDialog from './Components/AttendanceExportDialog';

export default {
  name: 'VAttendanceGroupList',
  components: {
    QTable,
    QTh,
    QTr,
    QTd,
    QBtn,
    QSelect,
    QIcon,
    AttendanceExportDialog,
  },
  mixins: [Localization, AttendanceListProvider],
  props: {
    dataConfig: {
      type: Object,
      default: () => ({}),
    },
    apiConfig: {
      type: Object,
      default: () => ({}),
    },
    withExport: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    // eslint-disable-next-line camelcase
    const { core_project } = window;
    const locale = core_project.locale || 'kz';
    // eslint-disable-next-line camelcase
    const {
      dataConfig: {
        groupList,
        organizationList,
        attendanceRoute,
        year,
        month,
        availableDates,
        kindergartenId,
        locality,
        type,
        columns,
        localities,
        userAllowedToSign,
        userAllowedToSend,
      },
    } = this;

    return {
      type,
      locale,
      year,
      month,
      availableDates,
      kindergartenId,
      columns,
      localities,
      locality,
      userAllowedToSign,
      userAllowedToSend,
      link: attendanceRoute,
      groups: groupList ?? organizationList,
      loading: false,
      exportModal: false,
      exportUrl: `${this.apiConfig.timeSheetGet.apiUrl}`,
    };
  },
  computed: {
    years() {
      const { availableDates } = this;
      return Object.keys(availableDates);
    },
    months() {
      const { availableDates, month } = this;
      return typeof availableDates[this.year] !== 'undefined'
        ? availableDates[this.year]
        : [month];
    },
    canExport() {
      return ['attendances-by-kindergarten', 'timesheet', 'voucher'].includes(this.type)
       && this.groups.length > 0;
    },
    canSign() {
      return this.type === 'voucher' && this.groups.length > 0 && this.userAllowedToSign;
    },
    canSend() {
      return this.type === 'voucher' && this.groups.length > 0 && this.userAllowedToSend;
    },
  },
  beforeCreate() {
    this.$trans.add(ATTENDANCE_TRANSLATIONS);
  },
  beforeMount() {
    const { apiConfig } = this;
    this.initLocale();
    this.initProvider(apiConfig);
  },
  methods: {
    makeRouteLink(props) {
      const { link, type } = this;
      const uri = window.location.search.substring(1);
      const params = new URLSearchParams(uri);
      const isFromMonitoring = params.get('fromMonitoring');
      const debatable = params.get('debatable');
      if (this.type === 'journal') {
        return `${link}/${props.row.id}`;
      }
      const month = `?month=${this.year}-${this.month.value}`;

      let additional = '';
      if (type === 'attendances-by-kindergarten' || isFromMonitoring) {
        additional += '&fromMonitoring=true';
      }
      if (debatable === '1') {
        additional += '&debatable=1';
      }

      return `${link}/${props.row.id}${month}${additional}`;
    },
    async onChangeYear(item) {
      this.groups = [];
      const {
        apiProvider,
        availableDates,
        month,
        kindergartenId,
        locality,
      } = this;
      const modelId = kindergartenId ?? locality?.value;
      if (availableDates[item].indexOf(month) === -1) {
        this.month = availableDates[item]
          .slice(0, 1)
          .shift();
      }
      this.loading = true;
      const data = await apiProvider.provideRefresh()
        .apply(this, [`${this.year}-${this.month.value}`, modelId]);
      if (data) {
        this.groups = data;
      }
      this.loading = false;
    },
    async onChangeMonth() {
      this.groups = [];
      const { apiProvider, kindergartenId, locality } = this;
      const modelId = kindergartenId ?? locality?.value;
      this.loading = true;
      const data = await apiProvider.provideRefresh()
        .apply(this, [`${this.year}-${this.month.value}`, modelId]);
      if (data) {
        this.groups = data;
      }
      this.loading = false;
    },
    async onChangeLocality() {
      this.groups = [];
      const { apiProvider, locality } = this;
      this.loading = true;
      const data = await apiProvider.provideRefresh()
        .apply(this, [`${this.year}-${this.month.value}`, locality.value]);
      if (data) {
        this.groups = data;
      }
      this.loading = false;
    },
    openExportModal() {
      if (this.type === 'attendances-by-kindergarten') {
        this.export();
      } else {
        this.exportModal = true;
      }
    },
    openSignModal() {
      if (this.type !== 'voucher') {
        return;
      }

      this.$sModal.open('v-modal-confirm-new', {
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm',
        binds: {
          type: 'request',
          action: this.apiConfig.sign.apiUrl,
          method: 'post',
          requestData: { date: `${this.year}-${this.month.value}` },
        },
      });
    },
    openSendModal() {
      if (this.type !== 'voucher') {
        return;
      }

      this.$sModal.open('v-modal-confirm-new', {
        title: this.trans(this.apiConfig.send.modalTitle),
        component: 'v-modal-confirm',
        binds: {
          type: 'request',
          action: this.apiConfig.send.apiUrl,
          method: 'post',
          requestData: { date: `${this.year}-${this.month.value}` },
          bodyText: this.apiConfig.send.modalText,
        },
      });
    },
    async export() {
      const {
        exportUrl, year, month, locality,
      } = this;
      this.loading = true;
      await axios({
        url: `${exportUrl}/${locality.value}?date=${year}-${month.value}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        const today = new Date();
        fileLink.setAttribute('download',
          `${this.trans('label.attendance_by_kindergartens')}-${today.getFullYear()}.${today.getMonth() + 1}.${today.getDate()
          }.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.loading = false;
      });
    },
  },
};
