import _ from 'lodash';
import {
  QItem,
  QSlideTransition,
} from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import VRegionSelect from '@app_balabaqsha/components/components/VRegionSelect';
import { ERR_404 } from '@vjs/helpers/requestWrapper';
import VTextModal from '@app_balabaqsha/components/modals/VTextModal';
import cookie from 'cookie';

export default {
  name: 'VVoucherQueueFilters',
  components: {
    QItem,
    VRegionSelect,
    VTextModal,
    QSlideTransition,
  },
  props: {
    uriLocality: {
      type: String,
      default: '',
    },
    uriStatistic: {
      type: String,
      default: '',
    },
    typeOptions: {
      type: Array,
      required: true,
    },
    isAdaptive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      modalRegionSelect: false,
      localitySelected: null,
      localityOptions: [],
      loading: true,
      typeSelected: this.typeOptions[0]?.value,
      type: this.typeOptions[0]?.type,
      showDescription: false,
    };
  },
  async mounted() {
    this.$emit('filter-loading', true);
    // Запрос населённых пунктов
    const localityRes = await requestWrapper.call(this, {
      url: this.uriLocality,
      params: { department: cookie.parse(document.cookie)?.department_id },
    }, true);

    if (localityRes.error) {
      this.loading = false;
      this.$emit('filter-loading', false);

      if (localityRes.errorType === ERR_404 && _.isBoolean(localityRes.data.region)) {
        this.modalRegionSelect = true;
      }

      return;
    }

    this.localityOptions = localityRes.data;
    this.loadFiltersFromSessionStorage({}, 'queueVoucherFilter');
    // Проверка на соответствие нас. пунтку при смене региона
    const checkLocality = _.find(
      this.localityOptions,
      loc => loc.value === this.localitySelected,
    );

    if (!checkLocality) {
      this.localitySelected = this.localityOptions[0]?.value;
      this.typeSelected = this.typeOptions[0].value;
      this.type = this.typeOptions[0].type;
    }

    await this.requestQueue();
    this.loading = false;
    this.$emit('filter-loading', false);
  },

  methods: {
    async handleUpdateLocality(val) {
      this.localityOptions = val.data;
      this.localitySelected = val.selected?.value;
      this.loadFiltersFromSessionStorage({
        localitySelected: val.selected,
      }, 'queueVoucherFilter');
      await this.requestQueue();
    },
    async requestQueue() {
      this.loading = true;
      this.$emit('filter-loading', true);

      const obj = {
        localitySelected: this.localitySelected,
        typeSelected: this.typeSelected,
        type: this.type,
      };

      this.saveFiltersToSessionStorage(obj, 'queueVoucherFilter');
      const filters = {
        locality: this.localitySelected,
      };
      const queueItemsRes = await requestWrapper.call(this, {
        url: this.typeSelected,
        params: filters,
      });
      const result = {
        items: [],
        statistics: [],
        filters,
      };

      if (!queueItemsRes.error) {
        result.items = (_.isArray(queueItemsRes.data) && queueItemsRes.data) || [];
        result.statistics = (_.isArray(queueItemsRes.statistics) && queueItemsRes.statistics) || [];
      }

      this.loading = false;
      this.$emit('filter-loading', false);
      this.$emit('filtered-list', result);
    },
    loadFiltersFromSessionStorage(rewriteKeysObj = {}, keyName = '', withValueCheck = false) {
      if (sessionStorage && sessionStorage.getItem(keyName)) {
        const keysObj = JSON.parse(sessionStorage.getItem(keyName));
        Object.keys(keysObj).forEach((item) => {
          if (rewriteKeysObj[item]) {
            keysObj[item] = rewriteKeysObj[item];
          }
          if (
            !withValueCheck
            || (withValueCheck && !this[item] && _.isEmpty(this[item] && this[item] !== 0))
          ) {
            this[item] = keysObj[item];
          }
        });
        return true;
      }
      return false;
    },
    // Сохранение выбранных фильтров в sessionStorage
    saveFiltersToSessionStorage(keysObj = {}, keyName = '') {
      if (sessionStorage) {
        sessionStorage.setItem(keyName, JSON.stringify(keysObj));
      }
    },
  },
};
