import _ from 'lodash';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QInput,
  QDate,
  QPopupProxy,
  QSelect,
  QTable,
  QIcon,
  QTr,
  QTh,
  QTd,
  QBtn,
  QTooltip,
} from '@quasar/components';
import axios from 'axios';
import { ERR_404 } from '@vjs/helpers/requestWrapper';


export default {
  name: 'VFreePlacesRecalledMonitoringView',
  components: {
    QInput,
    QDate,
    QPopupProxy,
    QSelect,
    QTable,
    QIcon,
    QTr,
    QTh,
    QTd,
    QBtn,
    QTooltip,
  },
  props: {
    apiUrl: {
      type: String,
    },
    colNames: {
      type: Array,
      required: true,
    },
    uriLocality: {
      type: String,
    },
    uriGetOrganizations: {
      type: String,
    },
  },
  data: () => ({
    form: {
      locality: null,
      organization: null,
      page: 1,
    },
    exportLoading: false,
    table: {
      inFullscreen: false,
      pagination: {
        rowsPerPage: 10,
      },
      columns: [],
      title: '',
    },
    rows: [],
    filters: {
      date: {
        from: '',
        to: '',
      },
    },
    filterString: '',
    localityOptions: [],
    orgNameOptions: [],
    localityOptionsRaw: [],
  }),
  async mounted() {
    await this.$trans.add(['statistics', 'button', 'menu', 'placeholder', 'bulletin']);
    this.requestGetLocalities();
    await this.requestGetData();

    const cols = [];

    this.colNames.forEach((itm, itmIdx) => {
      cols.push({
        name: itm.name,
        required: true,
        label: this.trans(`statistics.freeplace_recalls.${itm.label}`),
        align: itmIdx === 0 ? 'left' : 'center',
        field: itm.name,
        sortable: true,
        colspan: itm.colspan,
        rowspan: itm.rowspan,
      });
    });
    this.table.columns = cols;
  },
  beforeCreate() {
  },
  methods: {
    async requestOrg() {
      const orgRes = await requestWrapper.call(this, {
        url: this.uriGetOrganizations,
        params: {
          locality: this.form.locality,
        },
      });
      this.orgNameOptions = (_.isArray(orgRes.data) && orgRes.data) || [];

      if (this.organization && this.organization.value && this.orgNameOptions.length) {
        this.organization = { value: null };
      }
      this.requestGetData();
    },
    async requestGetLocalities() {
      const localityRes = await requestWrapper({
        url: this.uriLocality,
      });
      if (localityRes.error) {
        if (localityRes.errorType === ERR_404 && _.isBoolean(localityRes.data.region)) {
          this.modalRegionSelect = true;
        }
        return;
      }
      this.localityOptions = localityRes.data;
      this.localityOptionsRaw = localityRes.data;
    },
    filtersFn(val, update) {
      if (val === '') {
        update(() => {
          this.localityOptions = this.localityOptionsRaw;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.localityOptions = this.localityOptionsRaw.filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
      });
    },
    sum(col) {
      const rows = _.filter(
        this.rows,
        item => typeof item[col.name] === 'number',
      );
      return _.sumBy(rows, col.name);
    },
    clearFilters() {
      this.filters.date.to = '';
      this.filters.date.from = '';
      this.form.organization = '';
      this.orgNameOptions = [];
      this.form.locality = '';
      this.requestGetData();
    },

    async requestGetData() {
      this.$globalLoading.show();
      const dataRes = await requestWrapper({
        url: this.apiUrl,
        params: {
          to: this.filters.date.to,
          from: this.filters.date.from,
          locality: this.form.locality,
          organization_id: this.form.organization,
        },
      });

      if (!dataRes.error) {
        this.rows = dataRes.data;
      }
      this.$globalLoading.hide();
    },
    async exportu(e) {
      this.$globalLoading.show();
      this.loading = true;
      axios({
        url: `${this.apiUrl}?export=1`,
        method: 'GET',
        responseType: 'blob',
        params: {
          to: this.filters.date.to,
          from: this.filters.date.from,
          locality: this.form.locality,
          organization_id: this.form.organization,
        },
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } ${this.$trans.get('menu.monitoring_new.freeplaces-recalled')
          } ${this.filters.date.from ? this.$trans.get('statistics.file.from', { date: this.filters.date.from }) : ''
          } ${this.filters.date.to ? this.$trans.get('statistics.file.to', { date: this.filters.date.to }) : ''
          } ${this.form.locality ? this.$trans.get('statistics.freeplace_recalls.locality', { date: this.form.locality }) : ''
          } ${this.form.organization ? this.$trans.get('statistics.freeplace_recalls.org_name', { date: this.form.organization }) : ''
          } .xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.$globalLoading.hide();
        this.loading = false;
      });
    },
  },
  watch: {
    'filters.date.from': function () {
      this.requestGetData();
    },
    'filters.date.to': function () {
      this.requestGetData();
    },
  },

};
