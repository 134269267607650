import _ from 'lodash';
import {
  QPagination,
  QBtn,
} from '@quasar/components';
import VReferenceTime from '@vjs/components/VReferenceTime';
import {
  genArray,
  requestWrapper,
  scrollTop,
} from '@vjs/helpers';
import { ERR_403 } from '@vjs/helpers/requestWrapper';
import VStatisticPanel from '@vjs/components/VStatisticPanel';
import MXListView from '@vjs/mixins/MXListView';
import VBulletinItemModal
from '@app_balabaqsha/components/components/VBulletinItem/VBulletinItemModal.vue';
import VNoResults from '@vjs/components/VNoResults';
import VBulletinFilters from '../../components/VBulletinFilters';
import VBulletinItem from '../../components/VBulletinItem';

export default {
  name: 'VBulletinListView',
  mixins: [MXListView],
  components: {
    VBulletinItemModal,
    VBulletinFilters,
    VStatisticPanel,
    VBulletinItem,
    VReferenceTime,
    QPagination,
    VNoResults,
    QBtn,
  },
  props: {
    referenceTime: {
      type: Number,
      required: true,
    },
    linkToDirections: {
      type: String,
      required: true,
    },
    uriFilters: {
      type: String,
      required: true,
    },
    uriGetFilters: {
      type: String,
      required: true,
    },
    uriByAll: {
      type: String,
      required: true,
    },
    uriByOrg: {
      type: String,
      required: true,
    },
    uriCheck: {
      type: String,
      required: true,
    },
    uriStatements: {
      type: String,
      required: true,
    },
    uriStatementRequest: {
      type: String,
      required: true,
    },
    uriStatementDiseaseRequest: {
      type: String,
      required: true,
    },
    uriStatementSanatoriaRequest: {
      type: String,
      required: true,
    },
    uriStatementVoRequest: {
      type: String,
      default: null,
    },
    informPanelText: {
      type: String,
      default: () => '',
    },
    informPanelTextSeven: {
      type: String,
      default: () => '',
    },
    informPanelTextAboutLgot: {
      type: String,
      default: () => '',
    },
    linkToDownloadEarlyFreePlaces: {
      type: String,
      required: true,
    },
    nedbIntegration: {
      type: Boolean,
      default: false,
    },
    isVoucherActive: {
      type: Boolean,
      default: false,
    },
    defaultFilters: Object,
    disableLoadFromStorage: Boolean,
  },
  data() {
    return {
      currentTab: 'all',
      currentOrgID: null,
      itemsPaginate: [],
      correction: false,
      isSanatorium: false,
      isCorrection: false,
      firstLoad: false,
    };
  },
  beforeCreate() {
    this.$trans.add(['bulletin', 'label', 'directions', 'button', 'warning_text']);
  },
  computed: {
    countOfPages() {
      let count = parseInt(this.itemsFiltered.length / this.countByPage, 10);
      const remains = this.itemsFiltered.length % this.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
    itemsPaginated() {
      if (this.loading) {
        return genArray({
          activeLabel: ' ',
          ages: ' ',
          agesTitle: ' ',
          availableCount: ' ',
          count: ' ',
          dateEnd: ' ',
          dateStart: ' ',
          groupName: ' ',
          educationYear: ' ',
          id: 0,
          lang: '',
          organization: {
            address: ' ',
            name: ' ',
          },
          params: genArray({ key: ' ', value: ' ' }, 6),
          publishDate: ' ',
          is_correction: ' ',
          is_sanatorium: ' ',
        }, 4);
      }

      return this.itemsPaginate;
    },
  },
  methods: {
    handleChangeParams(key, count) {
      const item = this.itemsPaginate[key];
      item.params[item.params.length - 1].value = count;
      item.visibleRequestButton = item.params[item.params.length]?.value > 0;
    },
    async handleFilteredList(arr) {
      this.itemsFiltered = this.bulletinItems = arr.items || [];
      this.statisticItems = arr.statistics || [];
      this.calcPagination(1);

      if (!this.countOfPages || this.firstLoad) {
        await this.requestCheckStatements();
      }
      this.firstLoad = true;
    },
    calcPagination(val) {
      const current = (val - 1) * this.countByPage;
      this.itemsPaginate = this.itemsFiltered
        .slice(current, current + this.countByPage);
    },
    // Запускается на событие @input в q-pagination
    async changePagination(val) {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.catalog.getBoundingClientRect().top),
        500,
      );
      this.calcPagination(val);
      if (this.firstLoad) {
        await this.requestCheckStatements();
      }
    },
    async requestCheckStatements() {
      this.$globalLoading.show();
      if (!this.empty && !this.loading && this.currentTab === 'all') {
        const arrID = this.itemsPaginate.reduce((acc, item) => [...acc, item.id], []);
        const isCorrection = this.itemsPaginate.reduce((acc, item) => [...acc, item.correction], [])[0];
        const isSanatorium = this.itemsPaginate.reduce((acc, item) => [...acc, item.sanatoria], [])[0];
        const checkRes = await requestWrapper.call(this, {
          method: 'post',
          url: this.uriCheck,
          data: {
            data: arrID,
            correction: isCorrection,
            sanatorium: isSanatorium,
          },
        }, true);

        if (checkRes.error && checkRes.errorType === ERR_403) {
          this.$notify({
            type: 'warn',
            text: this.trans('bulletin.auth_warning', {
              login: '/login',
              reg: '/registrations',
            }),
          });
        }

        if (checkRes.data && typeof checkRes.data === 'object') {
          const checkID = checkRes.data;
          Object.keys(checkID).forEach((key) => {
            const item = _.find(this.itemsPaginate, o => o.id === parseInt(key, 10));
            item.visibleRequestButton = checkID[key];
          });
        }
      }
      this.$globalLoading.hide();
    },
    selectOrganization(orgID) {
      this.currentOrgID = orgID;
    },
  },
};
