import VStatementContractExtendModal from './VStatementContractExtendModal';
import VFundingDocumentsReasonModal from './VFundingDocumentsReasonModal';
import VParentPaymentUploadFileModal from './VParentPaymentUploadFileModal';
import VParentPaymentSubmitSignModal from './VParentPaymentSubmitSignModal';
import VEditSchoolLimitDialog from './VEditSchoolLimitDialog';
import VEditGroupLimitDialog from './VEditGroupLimitDialog';
import VNewProductInventoryDialog from './Food/VNewProductInventoryDialog';
import VAddProductQualityCertificate from './Food/VAddProductQualityCertificateDialog';
import VUploadFileModal from './VUploadFIleModal';
import VEditStatementSchoolsModal from './VEditStatementSchoolsModal';
import FoodRefuseModal from './Food/FoodRefuseModal';
import VoucherFunding from './VoucherFunding';
import Confirm from './Confirm';
import ConfirmWithMessage from './ConfirmWithMessage';
import FoodInfo from './FoodInfo';
import PersonnelTerminationModal from './PersonnelTerminationModal';
import SelectMapOrg from './SelectMapOrg';
import Inform from './Inform';
import InformCritical from './InformCritical';
import UserAdd from './UserAdd';
import CsiQuiz from './CsiQuiz';
import StatementLgots from './StatementLgots';
import AddContingent from './AddContingent';
import ConfirmCustomActions from './ConfirmCustomActions';
import ConfirmWithFields from './ConfirmWithFields';
import ChangeIssuer from './ChangeIssuer';
import WarningRolesModal from './WarningRolesModal';
import WarningModal from './WarningModal';

export default function (Vue) {
  VoucherFunding(Vue);
  Vue.component('v-edit-school-limit-dialog', VEditSchoolLimitDialog);
  Vue.component('v-edit-group-limit-dialog', VEditGroupLimitDialog);
  Vue.component('v-modal-confirm', Confirm);
  Vue.component('v-modal-confirm-with-message', ConfirmWithMessage);
  Vue.component('v-modal-food-info', FoodInfo);
  Vue.component('v-modal-personnel-termination', PersonnelTerminationModal);
  Vue.component('v-parent-payment-submit-sign-modal', VParentPaymentSubmitSignModal);
  Vue.component('v-modal-food-refuse', FoodRefuseModal);
  Vue.component('v-new-product-inventory-dialog', VNewProductInventoryDialog);
  Vue.component('v-add-product-quality-certificate-dialog', VAddProductQualityCertificate);
  Vue.component('v-statement-contract-extend-modal', VStatementContractExtendModal);
  Vue.component('v-parent-payment-upload-file-modal', VParentPaymentUploadFileModal);
  Vue.component('v-funding-documents-reason-modal', VFundingDocumentsReasonModal);
  Vue.component('v-upload-file-modal', VUploadFileModal);
  Vue.component('v-edit-statement-schools-modal', VEditStatementSchoolsModal);
  Vue.component('v-modal-select-map-org', SelectMapOrg);
  Vue.component('v-modal-inform', Inform);
  Vue.component('v-modal-inform-critical', InformCritical);
  Vue.component('v-modal-user-add', UserAdd);
  Vue.component('v-modal-csi-quiz', CsiQuiz);
  Vue.component('v-modal-statement-lgots', StatementLgots);
  Vue.component('v-modal-add-contingent', AddContingent);
  Vue.component('v-modal-confirm-custom-actions', ConfirmCustomActions);
  Vue.component('v-modal-confirm-with-fields', ConfirmWithFields);
  Vue.component('v-modal-change-issuer', ChangeIssuer);
  Vue.component('warning-roles-modal', WarningRolesModal);
  Vue.component('warning-modal', WarningModal);
}
