// noinspection NpmUsedModulesInstalled
import {
  FUNDING_KINDERGARTEN_TRANSLATIONS,
} from '@app_balabaqsha/consts';

import _ from 'lodash';

// noinspection NpmUsedModulesInstalled
import {
  QSeparator,
  QSelect,
  QTable,
  QTr,
  QTd,
  QTh,
  QBtn,
  QDialog,
  QSpinner,
  QTooltip,
  QIcon,
} from '@quasar/components';

// noinspection NpmUsedModulesInstalled
import { Confirm } from '@app_balabaqsha/components/views/VContingent/Dialog';

import VNoResults from '@vjs/components/VNoResults';
import axios from 'axios';
import Localization from '../Mixins/QuasarLocale';
import DateYearMonthPicker from '../Mixins/DateYearMonthPicker';
import PriceFormat from '../Mixins/PriceFormat';
import KindergartenListProvidersMixin from './Mixins/KindergartenListProviders';

export default {
  name: 'VFundingKindergartenList',
  components: {
    QSeparator,
    QSelect,
    QTable,
    QTr,
    QTd,
    QTh,
    QBtn,
    QDialog,
    QSpinner,
    Confirm,
    QTooltip,
    VNoResults,
    QIcon,
  },
  mixins: [
    Localization, PriceFormat,
    DateYearMonthPicker, KindergartenListProvidersMixin,
  ],
  props: {
    apiConfig: {
      type: Object,
      default: () => {
      },
    },
    dataConfig: {
      type: Object,
      default: () => {
      },
    },
    type: {
      type: String,
      default: () => 'funding',
    },
    detailUrl: {
      type: String,
      default: () => 'string',
    },
  },
  data() {
    // eslint-disable-next-line camelcase
    const { core_project } = window;
    const locale = core_project.locale || 'kz';
    // eslint-disable-next-line camelcase
    const { dataConfig: { department_id, date } } = this;

    return {
      locale,
      departmentId: department_id,
      currentDate: date,
      availableLocalities: [],
      selectedLocality: null,
      kindergartens: [],
      localityLoad: false,
      kindergartensLoad: false,
      totalPlan: 0,
      totalFact: 0,
      calculated: false,
      calculating: false,
      confirmed: false,
      confirmPopup: false,
      confirmStatus: false,
      denyStatus: false,
      denied: true,
      loading: false,
    };
  },
  computed: {
    anyIsLoading() {
      const { localityLoad, kindergartensLoad } = this;
      return localityLoad || kindergartensLoad;
    },
    kindergartenTableColumns() {
      const { currentDate, currentYear } = this;
      const trans = this.$trans;
      return [
        {
          name: 'name',
          field: 'name',
          label: trans.get('funding.kindergarten.table.name'),
        },
        {
          name: 'throughput',
          field: 'throughput',
          label: trans.get('funding.kindergarten.table.throughput'),
        },
        {
          name: 'count',
          field: 'count',
          label: trans.get('funding.kindergarten.table.contingent'),
        },
        {
          name: 'fundingPlanCount',
          field: 'fundingPlanCount',
          label: trans.get('funding.kindergarten.table.funding-count'),
        },
        {
          name: 'fundingPlan',
          field: 'fundingPlan',
          label: trans.get('funding.kindergarten.table.funding-plan-date', {
            date: currentDate.split('-').slice(0, 2).join('-'),
          }),
        },
        // {
        //   name: 'fundingPlanYear',
        //   field: 'fundingPlan',
        //   label: trans.get('funding.kindergarten.table.funding-plan-year', {
        //     year: currentYear,
        //   }),
        // },
        {
          name: 'id',
          field: 'id',
          label: '',
        },
      ];
    },
  },
  watch: {
    selectedLocality(value, old) {
      if (
        old === null
        || value.id !== old.id
      ) {
        const {
          loadKindergartens,
          setLastLocality,
        } = this;
        loadKindergartens(value.id);
        setLastLocality(value.id);

        this.calculated = false;
        this.confirmed = false;
        // noinspection JSUnresolvedVariable
        if (value.calcPlan) {
          this.calculated = value.calcPlan.id;
          if (value.confirmed) {
            this.confirmed = true;
          }
        } else {
          this.confirmed = false;
        }
      }
    },
  },
  beforeCreate() {
    this.$trans.add(FUNDING_KINDERGARTEN_TRANSLATIONS);
  },
  async beforeMount() {
    const {
      apiConfig,
    } = this;
    await this.initLocale();
    await this.initProvider(apiConfig);
    await this.loadLocalities();
    await this.setDefaultDate();
  },
  methods: {
    getLocalityById(id) {
      const { availableLocalities } = this;
      const locality = availableLocalities
        .reduce((acc, item) => {
          if (acc) {
            return acc;
          }
          return parseInt(id, 10) === parseInt(item.id, 10) ? item : null;
        }, null);

      return locality || availableLocalities.slice(0, 1)
        .shift();
    },
    lastLocality() {
      const { departmentId } = this;
      if (departmentId) {
        const key = `selected-funding-locality-${departmentId}`;
        return localStorage.getItem(key);
      }
      return null;
    },
    setLastLocality(value) {
      const { departmentId } = this;
      if (departmentId && value) {
        const key = `selected-funding-locality-${departmentId}`;
        localStorage.setItem(key, value);
      }
    },
    async loadLocalities() {
      const {
        apiProvider, departmentId, lastLocality, getLocalityById, selectedDate,
      } = this;
      this.localityLoad = true;
      this.confirmed = true;

      const { localities, date, availableDates } = await apiProvider
        .provideListLocalitiesByDepartmentId()
        .apply(this, [departmentId, selectedDate]);
      this.localityLoad = false;
      if (
        localities
        && Array.isArray(localities)
        && localities.length
      ) {
        this.availableLocalities = localities;

        this.selectedLocality = getLocalityById(lastLocality());

        this.confirmed = false;
        // noinspection JSUnresolvedVariable
        if (this.selectedLocality.calcPlan) {
          this.calculated = this.selectedLocality.calcPlan.id;
          if (this.selectedLocality.calcPlan.confirmed) {
            this.confirmed = true;
          }
        }

        this.denied = this.selectedLocality.denied;
      }
      if (date) {
        this.currentDate = date;
      }
      if (availableDates) {
        this.availableDates = availableDates;
      }
    },
    async loadKindergartens(localityId) {
      const { apiProvider, selectedDate } = this;
      this.kindergartens = [];
      this.kindergartensLoad = true;
      this.totalPlan = 0;
      this.totalFact = 0;
      const response = await apiProvider
        .provideListKindergartensByLocalityId()
        .apply(this, [localityId, selectedDate]);
      const { kindergartens, total } = response;
      this.kindergartensLoad = false;
      if (
        kindergartens
        && Array.isArray(kindergartens)
        && kindergartens.length
      ) {
        this.kindergartens = kindergartens;
      }
      // noinspection JSUnresolvedVariable
      this.totalPlan = total.plan || 0;
      // noinspection JSUnresolvedVariable
      this.totalFact = total.fact || 0;
    },
    async calculate() {
      const { apiProvider, selectedLocality } = this;
      this.calculating = true;
      const response = await apiProvider
        .provideCalculateAll()
        .apply(this, [selectedLocality.id]);
      this.calculating = false;

      if (typeof response.data !== 'undefined') {
        const { data: { calculated } } = response;
        await this.loadKindergartens(selectedLocality.id);
        if (calculated) {
          this.calculated = calculated;
        }
      }
    },
    confirmPopupShow() {
      this.confirmPopup = true;
    },
    cancelDeactivate() {
      this.confirmPopup = false;
    },
    async confirm() {
      const { apiProvider, calculated, selectedLocality } = this;
      this.confirmStatus = true;
      const response = await apiProvider
        .provideConfirm()
        .apply(this, [calculated]);
      this.confirmStatus = false;

      await this.loadLocalities();
      await this.loadKindergartens(selectedLocality.id);

      const { data: { status } } = response;
      if (status === 'ok') {
        this.confirmed = true;
      }
      this.confirmPopup = false;
    },
    async cancel() {
      const { apiProvider, calculated, selectedLocality } = this;
      this.confirmStatus = true;
      this.denyStatus = true;
      const response = await apiProvider
        .provideDeny()
        .apply(this, [calculated]);
      this.confirmStatus = false;
      this.denyStatus = false;

      await this.loadLocalities();
      await this.loadKindergartens(selectedLocality.id);

      const { data: { status } } = response;
      if (status === 'ok') {
        this.calculated = false;
      }
    },
    onChangeYear(val) {
      this.currentYear = val;
      this.currentMonth = _.first(this.availableDates[val]);
      // noinspection JSIgnoredPromiseFromCall
      this.loadLocalities();
      // noinspection JSIgnoredPromiseFromCall
      this.loadKindergartens(this.selectedLocality.id);
    },
    onChangeMonth(val) {
      this.currentMonth = val;
      // noinspection JSIgnoredPromiseFromCall
      this.loadLocalities();
      // noinspection JSIgnoredPromiseFromCall
      this.loadKindergartens(this.selectedLocality.id);
    },
    async exportu(e) {
      this.loading = true;

      axios({
        // url: `${this.apiConfig.kindergartens.apiUrl}?export=1`,
        // url: '/api/v1/control/worker/monitoring/portal-services?export=1',
        // url: '/api/v1/control/funding/locality/export',
        url: `${this.apiConfig.export.apiUrl}`,
        method: 'GET',
        responseType: 'blob',
        params: {
          locality_id: this.selectedLocality.id,
          export: true,
          date: this.currentDate,
        },
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        fileLink.setAttribute('download',
          `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()
          } - ${this.$trans.get('funding.kindergarten.table.funding-plan-date', {
            date: this.currentDate.split('-').slice(0, 2).join('-'),
          })}.xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loading = false;
      });
    },
  },
};
