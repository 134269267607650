import VMonitoringList from '@vjs/components/VMonitoringList';
import VEducationReportsView from './Education/EducationReportsView';
import VMonitoringBad from './VMonitoringBad/VMonitoringBad';
import VBulletinListView from './VBulletinListView';
import VQueueListView from './VQueueListView';
import VStatementCreateView from './VStatementCreateView';
import VFreePlaceCreateForm from './VFreePlace/VFreePlaceCreateForm.vue';
import VFreePlaceUpdateForm from './VFreePlace/VFreePlaceUpdateForm.vue';
import VOrganizationChildTable from './VContingent/VOrganizationChildTable.vue';
import VApplicationListView from './VApplicationListView';
import VDirectionList from './VDirectionList';
import VWorkerQueueListView from './VWorkerQueueListView';
import VCatalogListView from './VCatalogListView';
import VPassportPageView from './VPassportPageView';
import VPersonnelMonitoringView from './Monitorings/VPersonnelMonitoringView';
import VFreePlacesMonitoringView from './Monitorings/VFreePlacesMonitoringView';
import VContingentByPeriodMonitoringView from './Monitorings/VContingentByPeriodMonitoringView';
import VContingentMonitoringView from './Monitorings/VContingentMonitoringView';
import VQueueMonitoringView from './Monitorings/VQeueuMonitoringView';
import VDirectionsMonitoringView from './Monitorings/VDirectionsMonitoringView';
import VWorkerContingentListView from './VWorkerContingentListView';
import VGovServicesMonitoringView from './Monitorings/VGovServicesMonitoringView';
import VFreePlacesRecalledMonitoringView from './Monitorings/VFreePlacesRecalledMonitoringView';
import VEnrolledChildrensByAgeMonitoringView from './Monitorings/VEnrolledChildrensByAgeMonitoringView';
import VEnrolledChildrensByAgeMonitoringViewNew from './Monitorings/VEnrolledChildrensByAgeMonitoringViewNew';
import VQueueMonitoringByYearView from './Monitorings/VQueueMonitoringByYearView';
import VRegularStatementCreateView from './VRegularStatementCreateView';
import VAttendanceOrgList from './VAttendanceOrgList';
import VGroupCreateView from './VGroupCreateView';
import VProductCreateForm from './Food/VProductCreateForm';
import VFoodCostCalcView from './Food/VFoodCostCalcView';
import VSelectRoleForm from './VSelectRoleForm';
import VSelectOrganizationForm from './VSelectOrganizationForm';
import VMonitoringListArea from './Monitorings/VMonitoringListArea';
import VStatsCounter from './VStatsCounter';
import VStatementContractCreateForm from './VStatementContractCreateForm';
import VStatementContractShow from './VStatementContractShow';
import VStatementContractConcludeForm from './VStatementContractConcludeForm';
import UserFiles from './UserFiles';
import VParentPaymentForm from './ParentPayment/VParentPaymentForm';
import VParentPaymentCalculateView from './ParentPayment/VParentPaymentCalculateView';
import VParentPaymentCalculateHistoryView from './ParentPayment/VParentPaymentCalculateHistoryView';
import VParentPaymentNotificationsView from './ParentPayment/VParentPaymentNotificationsView';
import VFoodTechnologicalMap from './Food/VFoodTechnologicalMap';
import VFoodMenuCalendar from './Food/VFoodMenuCalendar';
import VParentPaymentRetirementView from './ParentPayment/VParentPaymentRetirementView';
import VFreePlacePredictions from './FreePlacePredictions';
import VParentPaymentReceiptView from './ParentPayment/VParentPaymentReceiptView';
import VParentPaymentReceiptHistoryView from './ParentPayment/VParentPaymentReceiptHistoryView';
import VFundingDocumentsView from './FundingDocuments';
import VFundingDocumentsMonthView from './FundingDocuments/FundingDocumentMonth';
import VFundingDocumentsChildrenView from './FundingDocuments/FundingDocumentChildren';
import VFundingDocumentsSchoolsView from './FundingDocuments/FundingDocumentSchools';
import VEnrollmentRequestSendForm from './VEnrollmentRequestSendForm';
import VFreeplacesListView from './VFreeplacesListView';
import VCorruptionMap from './VCorruptionMap';
import VRefuseActions from './VRefuseStatements/VRefuseActions';
import VRefuseArchiveStatementsList from './VRefuseStatements/VRefuseArchiveStatementsList';
import VRefuseStatementsList from './VRefuseStatements/VRefuseStatementsList';
import VAttendanceGroupList from './VAttendance/VAttendanceGroupList.vue';
import VJournalView from './VAttendance/VJournalView.vue';
import VJournalViewExtended from './VAttendance/VJournalViewExtended.vue';
import VTimeSheetView from './VAttendance/VTimeSheetView.vue';
import fundingComponents from './VFunding';
import voucherFundingComponents from './VVoucherFunding';

export default function (Vue) {
  fundingComponents(Vue);
  voucherFundingComponents(Vue);

  Vue.component('VEducationReportsView', VEducationReportsView);
  Vue.component('VMonitoringBad', VMonitoringBad);
  Vue.component('VBulletinListView', VBulletinListView);
  Vue.component('VQueueListView', VQueueListView);
  Vue.component('VStatementCreateView', VStatementCreateView);
  Vue.component('VFreePlaceCreateForm', VFreePlaceCreateForm);
  Vue.component('VFreePlaceUpdateForm', VFreePlaceUpdateForm);
  Vue.component('VOrganizationChildTable', VOrganizationChildTable);
  Vue.component('VApplicationListView', VApplicationListView);
  Vue.component('VDirectionList', VDirectionList);
  Vue.component('VWorkerQueueListView', VWorkerQueueListView);
  Vue.component('VCatalogListView', VCatalogListView);
  Vue.component('VPassportPageView', VPassportPageView);
  Vue.component('VPersonnelMonitoringView', VPersonnelMonitoringView);
  Vue.component('VFreePlacesMonitoringView', VFreePlacesMonitoringView);
  Vue.component('VContingentByPeriodMonitoringView', VContingentByPeriodMonitoringView);
  Vue.component('VContingentMonitoringView', VContingentMonitoringView);
  Vue.component('VQueueMonitoringView', VQueueMonitoringView);
  Vue.component('VDirectionsMonitoringView', VDirectionsMonitoringView);
  Vue.component('VWorkerContingentListView', VWorkerContingentListView);
  Vue.component('VMonitoringList', VMonitoringList);
  Vue.component('VGovServicesMonitoringView', VGovServicesMonitoringView);
  Vue.component('VFreePlacesRecalledMonitoringView', VFreePlacesRecalledMonitoringView);
  Vue.component('VEnrolledChildrensByAgeMonitoringView', VEnrolledChildrensByAgeMonitoringView);
  Vue.component('VEnrolledChildrensByAgeMonitoringViewNew', VEnrolledChildrensByAgeMonitoringViewNew);
  Vue.component('VQueueMonitoringByYearView', VQueueMonitoringByYearView);
  Vue.component('VRegularStatementCreateView', VRegularStatementCreateView);
  Vue.component('VAttendanceOrgList', VAttendanceOrgList);
  Vue.component('VGroupCreateView', VGroupCreateView);
  Vue.component('VProductCreateForm', VProductCreateForm);
  Vue.component('VFoodCostCalcView', VFoodCostCalcView);
  Vue.component('VSelectRoleForm', VSelectRoleForm);
  Vue.component('VSelectOrganizationForm', VSelectOrganizationForm);
  Vue.component('VMonitoringListArea', VMonitoringListArea);
  Vue.component('VStatsCounter', VStatsCounter);
  Vue.component('VStatementContractCreateForm', VStatementContractCreateForm);
  Vue.component('VStatementContractShow', VStatementContractShow);
  Vue.component('VStatementContractConcludeForm', VStatementContractConcludeForm);
  Vue.component('UserFiles', UserFiles);
  Vue.component('VParentPaymentForm', VParentPaymentForm);
  Vue.component('VParentPaymentCalculateView', VParentPaymentCalculateView);
  Vue.component('VParentPaymentCalculateHistoryView', VParentPaymentCalculateHistoryView);
  Vue.component('VParentPaymentNotificationsView', VParentPaymentNotificationsView);
  Vue.component('VFoodTechnologicalMap', VFoodTechnologicalMap);
  Vue.component('VFoodMenuCalendar', VFoodMenuCalendar);
  Vue.component('VParentPaymentRetirementView', VParentPaymentRetirementView);
  Vue.component('VFreePlacePredictions', VFreePlacePredictions);
  Vue.component('VParentPaymentReceiptView', VParentPaymentReceiptView);
  Vue.component('VParentPaymentReceiptHistoryView', VParentPaymentReceiptHistoryView);
  Vue.component('VFundingDocumentsView', VFundingDocumentsView);
  Vue.component('VFundingDocumentsMonthView', VFundingDocumentsMonthView);
  Vue.component('VFundingDocumentsChildrenView', VFundingDocumentsChildrenView);
  Vue.component('VFundingDocumentsSchoolsView', VFundingDocumentsSchoolsView);
  Vue.component('VEnrollmentRequestSendForm', VEnrollmentRequestSendForm);
  Vue.component('VFreeplacesListView', VFreeplacesListView);
  Vue.component('VCorruptionMap', VCorruptionMap);
  Vue.component('VRefuseActions', VRefuseActions);
  Vue.component('VRefuseArchiveStatementsList', VRefuseArchiveStatementsList);
  Vue.component('VRefuseStatementsList', VRefuseStatementsList);
  Vue.component('VAttendanceGroupList', VAttendanceGroupList);
  Vue.component('VJournalView', VJournalView);
  Vue.component('VJournalViewExtended', VJournalViewExtended);
  Vue.component('VTimeSheetView', VTimeSheetView);
}
