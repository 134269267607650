import axios from 'axios';
import { FALLBACK_LOCALE } from '@app_balabaqsha/consts';

export default class FreePlaceRepository {
  constructor({ storeApiUrl, updateApiUrl, locale }) {
    this.storeApiUrl = storeApiUrl || '';
    this.updateApiUrl = updateApiUrl || '';
    this.locale = locale || FALLBACK_LOCALE;
  }

  async store(data) {
    const { storeApiUrl, locale } = this;
    // eslint-disable-next-line no-return-await
    return await axios.post(
      storeApiUrl,
      data,
      {
        headers: { 'X-localization': locale },
      },
    );
  }

  async update(data, id) {
    const { updateApiUrl, locale } = this;
    // eslint-disable-next-line no-return-await
    return await axios.post(
      `${updateApiUrl}/${id}`,
      data,
      {
        headers: { 'X-localization': locale },
      },
    );
  }
}
