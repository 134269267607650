import axios from 'axios';
import eventHub from '@vjs/config/eventHub';
import url from '@vjs/helpers/url';
import FileUploadModal from '@app_balabaqsha/components/modals/FileUpload.vue';
import FileRemovalModal from '@app_balabaqsha/components/modals/FileRemoval.vue';

export default {
  props: {
    fields: {
      type: Array,
      default: () => ([]),
    },
    childId: {
      type: String,
      default: '',
    },
    parentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mutatedFields: this.fields,
      currentField: {},
    };
  },
  beforeCreate() {
    this.$trans.add(['label', 'button', 'notice']);
  },
  mounted() {
    eventHub.$on('fileUpload', () => {
      this.$refs.inputFile.click();
    });
    eventHub.$on('fileRemoval', this.removeFile);
  },
  beforeDestroy() {
    eventHub.$off('fileUpload');
    eventHub.$off('fileRemoval');
  },
  methods: {
    switchType(index) {
      this.currentField = this.mutatedFields[index];
      if (this.currentField.status !== 'downloaded') {
        this.$modal.open({
          title: this.trans('label.uploading_docs'),
          component: FileUploadModal,
        });
      } else {
        this.$modal.open({
          title: this.trans('notice.confirm'),
          component: FileRemovalModal,
        });
      }
    },
    async uploadFile() {
      const vm = this;
      try {
        const response = await axios({
          method: 'post',
          url: url('/documents/upload'),
          data: vm.formData(),
          headers: {
            'X-CSRF-TOKEN': window.core_project.csrfToken,
          },
        });
        if (response.data.status === 'success') {
          vm.currentField.status = 'downloaded';
          vm.currentField.userFile = response.data.userFile;
        } else {
          vm.notify(response.data);
        }
      } catch (error) {
        vm.notify(error);
      }
      vm.$modal.close();
      vm.$refs.inputFile.value = '';
    },
    async removeFile() {
      const vm = this;
      try {
        const response = await axios({
          method: 'delete',
          url: url('/documents/delete'),
          data: {
            _token: window.core_project.csrfToken,
            childId: this.childId,
            parentId: this.parentId,
            fileType: vm.currentField.file_type,
            fileName: vm.currentField.userFile.fileName,
          },
        });
        if (response.data.status === 'success') {
          vm.currentField.status = 'empty';
          vm.currentField.userFile = {};
        } else {
          vm.notify(response.data);
        }
      } catch (error) {
        vm.notify(error);
      }
      vm.$modal.close();
    },
    formData() {
      const formData = new FormData();
      formData.append('upload_file', this.$refs.inputFile.files[0]);
      formData.append('child_id', this.childId);
      formData.append('parent_id', this.parentId);
      formData.append('type', this.currentField.file_type);
      return formData;
    },
    notify(response) {
      this.$notify({
        type: response.status ? response.status : 'error',
        title: window.L.message,
        text: response.message,
      });
    },
  },
};
