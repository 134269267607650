/* eslint import/extensions: 0 */
import VFundingKindergartenList from './VFundingKindergartenList.vue';
import VFundingKindergartenListFinal from './VFundingKindergartenListFinal.vue';
import VFundingKindergartenGroupList from './VFundingKindergartenGroupList.vue';
import VFundingKindergartenGroupListFinal from './VFundingKindergartenGroupListFinal.vue';
import VFundingCalculation from './VFundingCalculation.vue';
import VFundingCalculationView from './VFundingCalculationView.vue';
import VFundingKindergartenSpecialList from './VFundingKindergartenSpecialList.vue';
import VFundingKindergartenListSpecialFinal from './VFundingKindergartenListSpecialFinal.vue';

export default function (Vue) {
  Vue.component('VFundingKindergartenList', VFundingKindergartenList);
  Vue.component('VFundingKindergartenGroupList', VFundingKindergartenGroupList);
  Vue.component('VFundingCalculation', VFundingCalculation);
  Vue.component('VFundingCalculationView', VFundingCalculationView);
  Vue.component('VFundingKindergartenListFinal', VFundingKindergartenListFinal);
  Vue.component('VFundingKindergartenGroupListFinal', VFundingKindergartenGroupListFinal);
  Vue.component('VFundingKindergartenSpecialList', VFundingKindergartenSpecialList);
  Vue.component('VFundingKindergartenListSpecialFinal', VFundingKindergartenListSpecialFinal);
}
