<template>
  <div>
    <v-spoiler
      v-for="(spoiler, spoilerKey) in items"
      :key="spoilerKey"
      :title="spoiler.title"
    >
      <div class="row">
        <div
          v-for="(itm, key) in spoiler.items"
          :key="key"
          class="col-lg-4 col-md-4 col-sm-12 s-desc-item"
        >
          <div class="s-desc-item__label">
            {{ itm.label }}:
          </div>
          <div class="s-desc-item__value">
            {{ itm.value }}
          </div>
        </div>
      </div>
    </v-spoiler>
  </div>
</template>

<script>
import VSpoiler from '@common/components/VSpoiler';

export default {
  name: 'NewTableInfoTables',
  components: { VSpoiler },
  props: {
    items: {
      type: [Array],
      default: () => ([]),
    },
  },
};
</script>
