import componentsComponents from './components';
import componentsViews from './views';
import componentsModals from './modals';
import componentsDefaultLocal from './default';


export default function (Vue) {
  componentsModals(Vue);
  componentsViews(Vue);
  componentsComponents(Vue);
  componentsDefaultLocal(Vue);
}
