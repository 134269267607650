import _ from 'lodash';
// noinspection NpmUsedModulesInstalled
import {
  QBtn,
  QBtnGroup,
  QCheckbox,
  QDate,
  QField,
  QIcon,
  QInput,
  QPopupProxy,
  QSelect,
  QOptionGroup,
} from '@quasar/components';
// noinspection NpmUsedModulesInstalled
import {
  COMMON_FREE_PLACE_TYPE,
  FREE_PLACE_FORM_TRANSLATIONS,
  TEMPORARY_FREE_PLACE_TYPE,
} from '@app_balabaqsha/consts';

import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import InputMixin from './Mixins/FreePlaceFields';
import ValidationMixin from './Mixins/Validation';
import ProvidersMixin from './Mixins/Providers';
import Localization from '../Mixins/QuasarLocale';

export default {
  name: 'VFreePlaceUpdateForm',
  components: {
    QField,
    QSelect,
    QInput,
    QCheckbox,
    QDate,
    QIcon,
    QPopupProxy,
    QBtn,
    QBtnGroup,
    QOptionGroup,
  },
  mixins: [InputMixin, ValidationMixin, ProvidersMixin, Localization],
  props: {
    apiConfig: {
      type: Object,
      default: () => {
      },
    },
    freePlace: {
      type: Object,
      default: () => {
      },
    },
    freePlaceAges: {
      type: Object,
      default: () => {
      },
    },
    dataConfig: {
      type: Object,
      default: () => {
      },
    },
    isPrivate: {
      type: Boolean,
      default: () => false,
    },
    groups: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    const {
      freePlaceTypes,
      freePlaceLanguages: languages,
    } = this.dataConfig;

    const {
      id, type, count, budget, active,
      // eslint-disable-next-line camelcase
      group_id, date_active, date_start, date_end,
    } = this.freePlace;

    // eslint-disable-next-line camelcase
    const { core_project } = window;
    const locale = core_project.locale || 'kz';

    return {
      // Providers
      apiProvider: null,
      locale,
      // Data
      freePlaceTypes,
      languages,
      // Validation
      // State
      id,
      active,
      budgetProxy: true,
      // eslint-disable-next-line camelcase
      selectedGroupId: group_id || null,
      // eslint-disable-next-line camelcase
      selectedTypeId: type || null,
      // eslint-disable-next-line camelcase
      dateActive: date_active || null,
      // eslint-disable-next-line camelcase
      dateStart: date_start || null,
      // eslint-disable-next-line camelcase
      dateEnd: date_end || null,
      groupTranslation: {},
      placeCount: count || null,
      budget: budget !== undefined ? (budget > 0) : null,
      sendingData: null,
      // Cache
      groupTranslations: {},
    };
  },
  computed: {
    groupLanguage() {
      const { groupTranslation: { value }, languages } = this;
      return languages[value] !== undefined
        ? languages[value]
        : null;
    },
    TEMPORARY_FREE_PLACE_TYPE: () => TEMPORARY_FREE_PLACE_TYPE,
    COMMON_FREE_PLACE_TYPE: () => COMMON_FREE_PLACE_TYPE,
  },
  beforeCreate() {
    this.$trans.add(FREE_PLACE_FORM_TRANSLATIONS);
  },
  beforeMount() {
    const {
      apiConfig: { groupLanguage, freePlace },
      dataConfig: { freePlaceTypes },
    } = this;

    this.selectedTypeId = this.freePlace.type || _.head(freePlaceTypes).id;
    if (this.selectedTypeId !== TEMPORARY_FREE_PLACE_TYPE) {
      this.dateEnd = null;
    }
    this.initLocale();
    this.initProvider(freePlace, groupLanguage);
    // noinspection JSIgnoredPromiseFromCall
    this.apiProvider.provideGetGroupLanguageByGroupId()
      .apply(this);

    this.hasCorrection = this.freePlace.is_correction === 1;
    this.hasSanatorium = this.freePlace.is_sanatorium === 1;
    if (this.freePlace.disease) {
      this.disease = this.freePlace.disease;
    }
    if (this.freePlace.sanatorium) {
      this.sanatoriumType = this.freePlace.sanatorium;
    }
    if (this.freePlace && this.selectedGroup) {
      this.setAgePlaceCount(this.freePlaceAges, true);
    }
  },
  methods: {
    async submit() {
      if (!this.validate()) {
        return;
      }

      this.$globalLoading.show();

      const data = this.prepareSendData();

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: `${this.apiConfig.freePlace.updateApiUrl}/${this.freePlace.id}`,
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
