<template>
  <div>
    <div class="s-modal__body">
      <q-input
        v-model="orderNumber"
        :label="trans('label.personnel_termination_order_number')"
        :placeholder="trans('label.personnel_termination_order_number')"
        required
      />
      <q-select
        v-if="reasons"
        v-model="reason"
        :options="reasons"
        :label="trans('label.reason_for_personnel_deletion')"
        emit-value
        map-options
        filled
      />
    </div>
    <div class="row-sort-between-btn">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        :disable="!canConfirm"
        @click="approve"
      />
    </div>
  </div>
</template>

<script>
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },
    method: {
      type: String,
      default: 'post',
    },
    getReasonsUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      orderNumber: '',
      reasons: [],
      reason: '',
    };
  },
  computed: {
    canConfirm() {
      return this.reasons && this.orderNumber;
    },
  },
  async beforeMount() {
    this.$globalLoading.show();
    const res = await requestWrapper.call(this, {
      url: this.getReasonsUrl,
      method: 'get',
    });
    if (!res.error) {
      this.reasons = res;
      this.reason = this.reasons[0].value;
    } else {
      this.$emit('onClose');
    }
    this.$globalLoading.hide();
  },
  methods: {
    async approve() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.action,
        method: this.method,
        [this.method === 'get' ? 'params' : 'data']: {
          reason: this.reason,
          termination_order_number: this.orderNumber,
        },
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
