import FundingCalcRepository
from '@app_balabaqsha/repositories/FundingCalcRepository';
import FundingStoreRepository
from '@app_balabaqsha/repositories/FundingStoreRepository';
import FundingFetchRepository
from '@app_balabaqsha/repositories/FundingFetchRepository';
import FundingApiProvider
from '@app_balabaqsha/providers/Kindergarten/FundingApiProvider';

const VoucherFundingProvider = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider({ calculateApi, storeApi, fetchApi }) {
      const trans = this.$trans.get;
      this.apiProvider = new FundingApiProvider({
        FundingFetchRepository: new FundingFetchRepository(fetchApi),
        FundingCalcRepository: new FundingCalcRepository(calculateApi),
        FundingStoreRepository: new FundingStoreRepository(storeApi),
      }, trans);
    },
  },
};

export default VoucherFundingProvider;
