import sentry from '@vendor/sentry';
import Translator from '@common/plugins/Translator';
import { eventBus } from '@common/mixins';
import store from '@app_balabaqsha/store';
import quasarApp from '@quasar/install';
import Notify from '@vjs/components/Notify/Notify';
import Notifications from '@vjs/plugins/Notification';
import axios from 'axios';
import {
  QBtn,
  QDialog,
  QForm,
  QInput,
  QSelect,
  QTooltip,
} from '@quasar/components';
import Inputmask from 'inputmask';
import PortalVue from 'portal-vue';
import plugin from '@common/plugins';

export default function (Vue) {
  plugin(Vue);
  Vue.use(PortalVue);
  const inputmaskPlugin = {
    install(_Vue, options) {
      _Vue.directive('mask', {
        bind(el, binding) {
          Inputmask(binding.value).mask(el);
        },
      });
    },
  };
  Vue.use(inputmaskPlugin);

  window.Vue = Vue;

  // region Deprecated

  const token = document.head.querySelector('meta[name="csrf-token"]');
  if (token) {
    window.$.ajaxSetup({
      headers: { 'X-CSRF-TOKEN': token.content },
    });
  } else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
  }
  // endregion

  sentry(Vue, 'https://4d38bb45fc7c4741a6b4ffbc5005fe7f@sentry.services.snation.kz/7');

  Vue.use(Translator, { store, eventBus });

  Vue.use(quasarApp, {
    components: {
      QTooltip,
      QInput,
      QForm,
      QDialog,
    },
  });
  Vue.component('q-btn', QBtn);
  Vue.component('q-select', QSelect);
  Vue.component('notify', Notify);
  Vue.use(Notifications);

  Vue.prototype.$axios = axios;

  if (window.core_project) {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': window.core_project.csrfToken || '',
      'Accept-Language': window.core_project.locale,
    };
  } else {
    // eslint-disable-next-line no-console
    console.log('No window.core_project! You\'re probably testing static components');
  }
}
