// noinspection NpmUsedModulesInstalled
import { FUNDING_CALCULATOR } from '@app_balabaqsha/consts';

// noinspection NpmUsedModulesInstalled
import { QBtn, QCheckbox, QRadio } from '@quasar/components';
import VSubheader from '@vjs/components/Text/VSubheader.vue';
import Localization from '../../Mixins/QuasarLocale';
import VoucherFundingProvider
from '../Mixins/VoucherFundingProvider';
import PriceFormat from '../../Mixins/PriceFormat';

export default {
  name: 'VVoucherFundingCalculator',
  components: {
    VSubheader,
    QBtn,
    QCheckbox,
    QRadio,
  },
  mixins: [Localization, PriceFormat, VoucherFundingProvider],
  props: {
    apiConfig: {
      type: Object,
      default: () => {
      },
    },
    context: {
      type: Object,
      default: () => {
      },
    },
    calculable: {
      type: Array,
      default: () => ([]),
    },
    listable: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    const { context } = this;
    return {
      active: true,
      ratios: context,
      calculated: false,
      calculateLoad: false,
      demo: true,
    };
  },
  computed: {
    type() {
      const { ratios: { type } } = this;
      return type;
    },
    constRatios() {
      // eslint-disable-next-line camelcase
      const { ratios: { ratio_list }, calculable } = this;
      return ratio_list.filter(item => calculable.indexOf(item.type) === -1);
    },
    calcRatios() {
      // eslint-disable-next-line camelcase
      const { ratios: { ratio_list }, calculable } = this;
      return ratio_list.filter(item => calculable.indexOf(item.type) !== -1);
    },
    selectableListRatios() {
      const { calcRatios, listable } = this;
      return calcRatios
        .filter(item => listable.indexOf(item.type) !== -1);
    },
    selectableSimpleRatios() {
      const { calcRatios, listable } = this;
      return calcRatios
        .filter(item => listable.indexOf(item.type) === -1);
    },
    isSelected() {
      const { selectableListRatios } = this;
      return selectableListRatios
        .reduce((acc, ratio) => (!acc || !ratio.selected ? false : acc), true);
    },
    availableFormulas() {
      // eslint-disable-next-line camelcase
      const { ratios: { formula_list } } = this;

      // noinspection JSUnresolvedVariable
      return formula_list.filter(
        item => ['plan', 'fact'].indexOf(item.formula) === -1
          && item.formula !== 'DOU_UZ_KE',
      );
    },
    availableFormulasDescription() {
      const { availableFormulas } = this;
      // noinspection JSUnresolvedVariable
      return availableFormulas.filter(item => item.calculation_description.length);
    },
  },
  beforeMount() {
    const {
      apiConfig,
    } = this;
    this.initLocale();
    this.initProvider(apiConfig);
  },
  beforeCreate() {
    this.$trans.add(FUNDING_CALCULATOR);
  },
  methods: {
    toggleSimpleRatio(type) {
      // eslint-disable-next-line camelcase
      const { ratios: { ratio_list } } = this;
      // eslint-disable-next-line camelcase
      this.ratios.ratio_list = ratio_list.map((item) => {
        let change = null;
        if (item.type === type) {
          change = {
            ...item,
            selected: !item.selected,
          };
        }
        return change || item;
      });
    },
    selectRatio(type, value) {
      // eslint-disable-next-line camelcase
      const { ratios: { ratio_list } } = this;
      // eslint-disable-next-line camelcase
      this.ratios.ratio_list = ratio_list.map((item) => {
        let change = null;
        if (item.type === type) {
          change = {
            ...item,
            selected: value,
          };
        }
        return change || item;
      });
    },
    getFormulaByName(name) {
      const { availableFormulas } = this;
      return availableFormulas.reduce((acc, item) => {
        if (acc) {
          return acc;
        }
        // noinspection JSUnresolvedVariable
        return item.formula === name ? item : null;
      }, null);
    },
    getRatioByType(type) {
      // eslint-disable-next-line camelcase
      const { ratios: { ratio_list } } = this;
      return ratio_list.reduce((acc, item) => {
        if (acc) {
          return acc;
        }
        return item.type === type ? item : null;
      }, null);
    },
    async calculate() {
      const { apiProvider, ratios } = this;
      this.calculateLoad = true;
      this.calculated = false;

      const response = await apiProvider
        .provideCalculate()
        .apply(this, [ratios]);
      this.calculateLoad = false;
      if (response) {
        this.calculated = true;
        this.ratios = response;
      }
    },
    async save() {
      const { apiProvider, ratios } = this;

      const response = await apiProvider
        .provideStore()
        .apply(this, [ratios]);

      if (response) {
        this.active = false;
        this.calculated = false;
        this.$emit('close');
      }
    },
  },
};
