import EntityListDateFetchRepository from '@app_balabaqsha/repositories/EntityListDateFetchRepository';
import FinalListFundingCalcRepository from '@app_balabaqsha/repositories/FinalListFundingCalcRepository';
import FinalListFundingActRepository from '@app_balabaqsha/repositories/FinalListFundingActRepository';
import FinalFundingListProvider from '@app_balabaqsha/providers/Kindergarten/FinalFundingListProvider';

const FinalVoucherFundingListProviders = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider({
      kindergartens,
      calculate,
      confirm,
      abort,
    }) {
      const trans = this.$trans.get;
      this.apiProvider = new FinalFundingListProvider({
        EntityListDateFetchRepository: new EntityListDateFetchRepository(kindergartens),
        EntityListDateConfirmRepository: new FinalListFundingActRepository(confirm),
        FinalListFundingCalcRepository: new FinalListFundingCalcRepository(calculate),
        EntityListDateCancelRepository: new FinalListFundingActRepository(abort),
      }, trans);
    },
  },
};

export default FinalVoucherFundingListProviders;
