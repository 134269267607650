<template>
  <div class="s-time-sheet-container">
    <div class="s-time-header">
      <slot name="header" />
    </div>
    <div class="s-time-sheet">
      <div class="s-time-sheet-fixed-part-container">
        <div class="s-time-sheet-fixed-part">
          <slot name="headFixedRow" />
          <v-ts-row
            ref="fixedHeader"
            :height="headerHeight"
            :class-name="rowClassName"
          >
            <v-ts-cell
              v-for="col in fixedColumns"
              :key="columnName(col)"
              :class-name="headerCellClassName()"
            >
              {{ columnName(col) }}
            </v-ts-cell>
          </v-ts-row>
          <v-ts-row
            v-for="(row, index) in rows"
            :ref="`fixedRow${index}`"
            :key="index"
            :height="rowHeight[index] || null"
          >
            <slot
              name="fixedRow"
              :props="{ index, row }"
            >
              <v-ts-cell
                v-for="col in fixedColumns"
                :key="columnName(col) + '-' + index"
              >
                <slot
                  name="cell"
                  :props="{ col, row, index }"
                >
                  {{ getValueByColumn(row, col) }}
                </slot>
              </v-ts-cell>
            </slot>
          </v-ts-row>
        </div>
      </div>
      <div class="s-time-sheet-scroll-part-container">
        <div
          ref="topScrollWrapper"
          style="overflow-x: scroll; width: 100%; height: 10px;"
          @scroll.passive="handleTopScroll"
        >
          <div
            ref="topScrollBody"
            :style="{
              width: `${scrollWidth}px`,
            }"
          />
        </div>
        <div
          ref="bottomScrollWrapper"
          style="overflow-x: scroll; width: 100%;"
          @scroll.passive="handleBottomScroll"
        >
          <div
            ref="bottomScrollBody"
            class="s-time-sheet-scroll-part"
          >
            <slot name="headScrollRow" />
            <v-ts-row
              ref="scrollHeader"
              :height="headerHeight"
              :class-name="rowClassName"
            >
              <v-ts-cell
                v-for="(col, index) in scrollColumns"
                :key="columnName(col) + '-' + index"
                :class-name="headerCellClassName(col.field)"
              >
                {{ columnName(col) }}
              </v-ts-cell>
            </v-ts-row>
            <v-ts-row
              v-for="(row, index) in rows"
              :ref="`scrollRow${index}`"
              :key="index"
              :height="rowHeight[index] || null"
            >
              <slot
                name="scrollRow"
                :props="{ index, row }"
              >
                <v-ts-cell
                  v-for="(col, index) in scrollColumns"
                  :key="columnName(col) + '-' + index"
                >
                  <slot
                    name="cell"
                    :props="{ col, row, index }"
                  >
                    {{ getValueByColumn(row, col) }}
                  </slot>
                </v-ts-cell>
              </slot>
            </v-ts-row>
          </div>
        </div>
      </div>
    </div>
    <div class="s-time-footer">
      <slot name="header" />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import { cn } from '@app_balabaqsha/helpers/support';
import VTsRow from './VTsRow.vue';
// eslint-disable-next-line import/extensions
import VTsCell from './VTsCell.vue';


export default {
  name: 'VTimeSheet',
  components: {
    VTsRow,
    VTsCell,
  },
  props: {
    columns: {
      type: Array,
      default: () => ([]),
    },
    rows: {
      type: Array,
      default: () => ([]),
    },
    rowClassName: {
      type: [Object, String],
      default: () => ({}),
    },
    cellClassName: {
      type: [Object, String],
      default: () => ({}),
    },
    headCellClassName: {
      type: [Object, String],
      default: () => ({}),
    },
    checkIsCurrentDay: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      headerHeight: null,
      rowHeight: {},
      scrolling: false,
      scrollWidth: 0,
    };
  },
  computed: {
    fixedColumns() {
      const { columns } = this;
      if (!Array.isArray(columns)) {
        return [];
      }
      return columns.filter(
        col => typeof col.fixed !== 'undefined' && col.fixed === true,
      );
    },
    scrollColumns() {
      const { columns } = this;
      if (!Array.isArray(columns)) {
        return [];
      }
      return columns.filter(
        (col) => {
          if (typeof col !== 'object') {
            return true;
          }
          return typeof col.fixed === 'undefined' || col.fixed === false;
        },
      );
    },
    headerCellClassName() {
      return (date) => {
        const { headCellClassName } = this;
        const currentDayClass = this.checkIsCurrentDay(date) ? 's-time-sheet-cell-header--current' : '';
        return cn(headCellClassName, `s-time-sheet-cell-header ${currentDayClass}`, date);
      };
    },
  },
  methods: {
    handleSetScrollWidth() {
      if (this.$refs.bottomScrollBody) {
        this.scrollWidth = this.$refs.bottomScrollBody.offsetWidth;
      } else {
        this.scrollWidth = 0;
      }
    },
    handleTopScroll() {
      if (this.scrolling) {
        this.scrolling = false;
        return;
      }
      this.scrolling = true;
      this.$refs.bottomScrollWrapper.scrollLeft = this.$refs.topScrollWrapper.scrollLeft;
    },
    handleBottomScroll() {
      if (this.scrolling) {
        this.scrolling = false;
        return;
      }
      this.scrolling = true;
      this.$refs.topScrollWrapper.scrollLeft = this.$refs.bottomScrollWrapper.scrollLeft;
    },
    scrollTableToToday() {
      // if (this.$q.platform.is.mobile) {
      const currentDayColEl = document.getElementsByClassName('s-time-sheet-cell-header--current')[0];
      const scrollContainerEl = this.$refs.bottomScrollWrapper;
      if (currentDayColEl && scrollContainerEl) {
        const elLeft = currentDayColEl.offsetLeft + currentDayColEl.offsetWidth;
        const elParentLeft = scrollContainerEl.offsetLeft + scrollContainerEl.offsetWidth;
        if (elLeft >= elParentLeft) {
          scrollContainerEl.scrollLeft = elLeft - elParentLeft;
        }
      }
      // }
    },
    reCalcSize() {
      this.calcHeaderHeight();
      this.calcRowsHeight();
    },
    getValueByColumn(item, col) {
      let colName = null;
      if (
        typeof col === 'string'
          || typeof col === 'symbol'
          || typeof col === 'number'
      ) {
        colName = col;
      }

      if (
        typeof col === 'object'
          && (
            typeof col.name !== 'undefined'
            || typeof col.field !== 'undefined'
          )
      ) {
        colName = typeof col.field !== 'undefined'
          ? col.field
          : col.name;
      }

      if (colName === null) {
        return null;
      }

      return typeof item[colName] !== 'undefined'
        ? item[colName]
        : null;
    },
    columnName(col) {
      if (
        typeof col === 'string'
          || typeof col === 'symbol'
          || typeof col === 'number'
      ) {
        return col;
      }

      if (
        typeof col === 'object'
          && (
            typeof col.label !== 'undefined'
            || typeof col.name !== 'undefined'
          )
      ) {
        return col.label !== 'undefined'
          ? col.label
          : col.name;
      }
      return '';
    },
    calcHeaderHeight() {
      const { $refs: { fixedHeader, scrollHeader } } = this;
      if (
        typeof fixedHeader === 'undefined'
          || typeof scrollHeader === 'undefined'
      ) {
        return null;
      }
      this.headerHeight = fixedHeader.getRow().clientHeight > scrollHeader.getRow().clientHeight
        ? fixedHeader.getRow().clientHeight
        : scrollHeader.getRow().clientHeight;
      return this.headerHeight;
    },
    calcRowsHeight() {
      const { rows, $refs, rowHeight } = this;
      for (const [index, val] of rows.entries()) {
        const fixedRow = $refs[`fixedRow${index}`].shift();
        const scrollRow = $refs[`scrollRow${index}`].shift();
        if (
          typeof fixedRow !== 'undefined'
            && typeof scrollRow !== 'undefined'
        ) {
          rowHeight[index] = fixedRow.getRow().clientHeight > scrollRow.getRow().clientHeight
            ? fixedRow.getRow().clientHeight
            : scrollRow.getRow().clientHeight;
        }
      }
      this.rowHeight = rowHeight;
      return null;
    },
  },
};
</script>
