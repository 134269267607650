import { Helper } from '@common/src/helpers';
import Validation from '@vjs/mixins/Validation';
import { QIcon } from '@quasar/components';

export default {
  components: { QIcon },
  mixins: [Validation],
  props: {
    checkIinParent: '',
    checkIinChild: '',
    nationalRefs: '',
    groupRefs: '',
    diseaseRefs: '',
    submit: '',
    informConfig: {
      type: Object,
      default: () => ({
        message: '',
        color: 'red',
      }),
    },
  },
  data() {
    return {
      form: {
        parent: {
          iin: null,
          fullName: null,
          iinFilled: false,
        },
        child: {
          iin: null,
          fullName: null,
          birthdate: null,
          nationality: null,
          iinFilled: false,
        },
        statement: {
          arrivalDate: null,
          orderNumber: null,
          group: null,
          groupType: null,
          disease: null,
          document: [],
        },
      },
      refs: {
        nationalities: [],
        groups: [],
        diseases: [],
      },
      disabled: {
        nationality: true,
      },
    };
  },
  beforeCreate() {
    this.$trans.add([
      'text',
      'button',
      'placeholder',
      'validation',
    ]);
  },

  computed: {
    isNotRegularGroup() {
      const { groupType } = this.form.statement;
      return groupType && groupType !== 'regular';
    },
    documentLabel() {
      const { groupType } = this.form.statement;
      switch (groupType) {
        case 'special': return this.trans('label.contingent_group_document_special');
        case 'sanatorium': return this.trans('label.contingent_group_document_sanatorium');
        default: return '';
      }
    },
  },

  methods: {
    clearForm() {
      if (this.form.child.iinFilled) {
        this.form.child.iin = null;
        this.form.child.fullName = null;
        this.form.child.birthdate = null;
        this.form.child.nationality = null;
        this.form.child.iinFilled = false;
        this.form.statement.arrivalDate = null;
        this.form.statement.orderNumber = null;
        this.form.statement.group = null;
        this.form.statement.groupType = null;
        this.form.statement.disease = null;
        this.form.statement.document = [];
        this.form.refs.groups = [];
        this.form.refs.diseases = [];
      } else if (this.form.parent.iinFilled) {
        this.form.parent.iin = null;
        this.form.parent.fullName = null;
        this.form.parent.iinFilled = false;
      }
    },
    handleCancel() {
      if (this.form.child.iinFilled || this.form.parent.iinFilled) {
        this.clearForm();
      } else {
        this.$emit('onClose');
      }
    },
    async checkIin(iin, isChild) {
      if (!this.validateIin(iin)) {
        return false;
      }

      this.$globalLoading.show();

      const url = isChild ? this.checkIinChild : this.checkIinParent;
      const data = { iin };
      if (isChild) {
        data.parentIin = this.form.parent.iin;
      }

      const res = await requestWrapper.call(this, {
        url,
        method: 'post',
        data,
      });

      if (!res.error) {
        if (isChild) {
          this.form.child.iinFilled = true;
          this.form.child.fullName = res.fullName;
          this.form.child.birthdate = res.birthdate;
          this.form.child.nationality = res.nationality;
          this.disabled.nationality = !!res.nationality;
          await requestGetDataHelper(this, {
            url: this.groupRefs,
            variableName: 'refs.groups',
            hardSet: true,
            params: { iin },
          });
          if (!this.refs.nationalities?.length) {
            await requestGetDataHelper(this, {
              url: this.nationalRefs,
              variableName: 'refs.nationalities',
              hardSet: true,
            });
          }
        } else {
          this.form.parent.iinFilled = true;
          this.form.parent.fullName = res.fullName;
        }
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
    async handleGroupInput({ groupType }) {
      this.form.statement.document = [];
      this.form.statement.groupType = groupType;
      await requestGetDataHelper(this, {
        url: this.diseaseRefs,
        variableName: 'refs.diseases',
        hardSet: true,
        params: { groupType },
      });
    },
    async request() {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url: this.submit,
        method: 'post',
        data: this.form,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
