/* eslint import/extensions: 0 */
import VVoucherFundingKindergartenGroupListFinal from './VVoucherFundingKindergartenGroupListFinal';
import VVoucherFundingCalculationView from './VVoucherFundingCalculationView.vue';
import VVoucherFundingKindergartenList from './VVoucherFundingKindergartenList';
import VVoucherFundingKindergartenListFinal from './VVoucherFundingKindergartenListFinal';
import VVoucherFundingKindergartenGroupList from './VVoucherFundingKindergartenGroupList';
import VVoucherFundingCalculation from './VVoucherFundingCalculation';
import VVoucherFundingKindergartenSpecialList from './VVoucherFundingKindergartenSpecialList';
import VVoucherFundingKindergartenListSpecialFinal from './VVoucherFundingKindergartenListSpecialFinal';
import VVoucherFundingKindergartenGroupContingentList from './VVoucherFundingKindergartenGroupContingentList';
import VVoucherFundingKindergartenSpecialListHead from './VVoucherFundingKindergartenSpecialListHead';
import VVoucherFundingKindergartenListSpecialFinalHead from './VVoucherFundingKindergartenListSpecialFinalHead';

import VVoucherApplicationInfoForm from './Application/InfoForm';
import VVoucherApplicationForm from './Application/Form';
import VVoucherApplicationShow from './Application/Show';
import VVoucherFaq from './VVoucherFaq';
import VVoucherFaqAlt from './VVoucherFaqAlt';
import VVoucherContractFaq from './VVoucherContractFaq';

import VVoucherQueue from './VVoucherQueue';
import VVoucherAbout from './VVoucherAbout';
import VVoucherFcReport from './VVoucherFcReport';
import VVoucherTimesheetMonitoring from './VVoucherTimesheetMonitoring';

export default function (Vue) {
  Vue.component('VVoucherFundingKindergartenList', VVoucherFundingKindergartenList);
  Vue.component('VVoucherFundingKindergartenGroupList', VVoucherFundingKindergartenGroupList);
  Vue.component('VVoucherFundingCalculation', VVoucherFundingCalculation);
  Vue.component('VVoucherFundingCalculationView', VVoucherFundingCalculationView);
  Vue.component('VVoucherFundingKindergartenListFinal', VVoucherFundingKindergartenListFinal);
  Vue.component('VVoucherFundingKindergartenGroupListFinal', VVoucherFundingKindergartenGroupListFinal);
  Vue.component('VVoucherFundingKindergartenSpecialList', VVoucherFundingKindergartenSpecialList);
  Vue.component('VVoucherFundingKindergartenListSpecialFinal', VVoucherFundingKindergartenListSpecialFinal);
  Vue.component('VVoucherFundingKindergartenGroupContingentList', VVoucherFundingKindergartenGroupContingentList);
  Vue.component('VVoucherFundingKindergartenSpecialListHead', VVoucherFundingKindergartenSpecialListHead);
  Vue.component('VVoucherFundingKindergartenListSpecialFinalHead', VVoucherFundingKindergartenListSpecialFinalHead);

  Vue.component('VVoucherApplicationForm', VVoucherApplicationForm);
  Vue.component('VVoucherApplicationInfoForm', VVoucherApplicationInfoForm);
  Vue.component('VVoucherApplicationShow', VVoucherApplicationShow);
  Vue.component('VVoucherFaq', VVoucherFaq);
  Vue.component('VVoucherFaqAlt', VVoucherFaqAlt);
  Vue.component('VVoucherContractFaq', VVoucherContractFaq);

  Vue.component('VVoucherQueue', VVoucherQueue);
  Vue.component('VVoucherAbout', VVoucherAbout);
  Vue.component('VVoucherFcReport', VVoucherFcReport);
  Vue.component('VVoucherTimesheetMonitoring', VVoucherTimesheetMonitoring);
}
