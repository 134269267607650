import SelectUserFile from './SelectUserFile';
import VApplicationItem from './VApplicationItem';
import VBulletinFilters from './VBulletinFilters';
import VBulletinItem from './VBulletinItem';
import VCatalogFilters from './VCatalogFilters';
import VCatalogItem from './VCatalogItem';
import VDigitalSignBtn from './VDigitalSignBtn';
import VDirection from './VDirection';
import VFreeplaceItem from './VFreeplaceItem';
import VPageContent from './VPageContent';
import VQueueFilters from './VQueueFilters';
import VQueueItem from './VQueueItem';
import VRegionSelect from './VRegionSelect';
import VSelectFromList from './VSelectFromList';
import VSelectFromListOption from './VSelectFromListOption';
import VStatementQuestionnaire from './VStatementQuestionnaire';
import VTimeSheet from './VTimeSheet';

export default function (Vue) {
  Vue.component('SelectUserFile', SelectUserFile);
  Vue.component('VApplicationItem', VApplicationItem);
  Vue.component('VBulletinFilters', VBulletinFilters);
  Vue.component('VBulletinItem', VBulletinItem);
  Vue.component('VCatalogFilters', VCatalogFilters);
  Vue.component('VCatalogItem', VCatalogItem);
  Vue.component('VDigitalSignBtn', VDigitalSignBtn);
  Vue.component('VDirection', VDirection);
  Vue.component('VFreeplaceItem', VFreeplaceItem);
  Vue.component('VPageContent', VPageContent);
  Vue.component('VQueueFilters', VQueueFilters);
  Vue.component('VQueueItem', VQueueItem);
  Vue.component('VRegionSelect', VRegionSelect);
  Vue.component('VSelectFromList', VSelectFromList);
  Vue.component('VSelectFromListOption', VSelectFromListOption);
  Vue.component('VStatementQuestionnaire', VStatementQuestionnaire);
  Vue.component('VTimeSheet', VTimeSheet);
}
