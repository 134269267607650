import {
  QForm, QSlideTransition, QField, QTooltip, QBtn, QBtnDropdown, QInput, QSelect,
} from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
import VTwoFieldWrapper from '@common/components/VTwoFieldWrapper';
import EgovSign from '@common/components/Modals/EgovSign';

export default function (Vue) {
  Vue.component('q-form', QForm);
  Vue.component('q-slide-transition', QSlideTransition);
  Vue.component('q-field', QField);
  Vue.component('q-tooltip', QTooltip);
  Vue.component('q-btn', QBtn);
  Vue.component('q-btn-dropdown', QBtnDropdown);
  Vue.component('q-input', QInput);
  Vue.component('q-select', QSelect);

  Vue.component('v-form-inform', VFormInform);
  Vue.component('v-two-field-wrapper', VTwoFieldWrapper);

  // Modal components
  Vue.component('v-modal-egov-sign', EgovSign);
}
