import axios from 'axios';
import { FALLBACK_LOCALE } from '@app_balabaqsha/consts';

export default class ExtendedJournalRepository {
  constructor({
    apiUrl,
    locale,
  }) {
    this.url = apiUrl || '';
    this.locale = locale || FALLBACK_LOCALE;
  }

  async fetchData(groupId, date) {
    const { url, locale } = this;
    // eslint-disable-next-line no-return-await
    return await axios.get(
      `${url}/${groupId}`,
      {
        headers: { 'X-localization': locale },
        params: {
          date,
        },
      },
    );
  }
}
