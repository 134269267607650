import { QIcon } from '@quasar/components';

export default {
  name: 'VNotify',
  components: { QIcon },

  data() {
    return {
      items: [],
      maxVisible: 3,
    };
  },

  async mounted() {
    await this.$trans.add(['notice', 'egov_sign']);
    this.handleBackendMessages();

    this.$eventHub.$on('v-notify-top-right', this.addNotify);
  },

  methods: {
    handleBackendMessages() {
      const messages = [];
      const core = window.core_project;
      if (core) {
        const addMessage = (type, message) => {
          messages.push({ type, message });
        };
        const handleType = (type) => {
          if (core[type]) {
            const tmpType = type.slice(0, -1);
            const msgType = tmpType === 'message' ? 'success' : tmpType;
            if (Array.isArray(core[type])) {
              _.forEach(core[type], itm => addMessage(msgType, itm));
            } else if (typeof core[type] === 'string') {
              addMessage(msgType, core[type]);
            }
            delete core[type];
          }
        };
        handleType('messages');
        handleType('errors');
        handleType('warns');
        handleType('infos');
        if (messages?.length) {
          messages.forEach((item) => {
            this.$notify({
              type: item.type,
              text: item.message,
            });
          });
        }
      }
    },
    getTitleByType(type) {
      return this.trans(`notice.${type === 'success' ? 'message' : type}`);
    },
    addNotify(item) {
      if (this.items.length >= this.maxVisible) {
        this.removeNotify(0)();
      }

      this.items.push({
        ...item,
        key: (`${Math.random()}`).substr(2, 18),
      });
    },

    removeNotify(index) {
      return () => {
        this.items.splice(index, 1);
      };
    },

    getIcon(type) {
      let name = '';
      switch (type) {
        case 'error': name = 'times-circle'; break;
        case 'warn': name = 'exclamation-triangle'; break;
        case 'info': name = 'exclamation-circle'; break;
        default: name = 'check-circle'; break;
      }

      return `s-str-ico-fa s-str-ico-fa--thm-${name}`;
    },

    createNotify(h, item, itemIdx) {
      return h('div', {
        key: item.text + item.key,
        staticClass: `sn-notify sn-notify--${item.type}`,
      }, [
        h('div', { staticClass: 'sn-notify__head' }, [
          h('button', {
            staticClass: 'sn-notify__close',
            on: {
              click: this.removeNotify(itemIdx),
            },
          }),
          h('div', { staticClass: `sn-notify__title ${this.getIcon(item.type)}` }, item.title ?? this.getTitleByType(item.type)),
        ]),
        h('div', { staticClass: 'sn-notify__text', domProps: { innerHTML: item.text } }),
      ]);
    },
  },

  render(h) {
    return h('div', [
      h('transition-group', {
        staticClass: 'sn-notify__wrapper sn-notify__wrapper--pos-top-right',
        props: {
          name: 'sn-transition--flip-right',
          tag: 'div',
        },
      }, this.items.map((item, itemIdx) => this.createNotify(h, item, itemIdx))),
    ]);
  },
};
