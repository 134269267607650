import axios from 'axios';
import _ from 'lodash';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QInput,
  QDate,
  QPopupProxy,
  QSelect,
  QTable,
  QIcon,
  QTr,
  QTh,
  QTd,
  QBtn,
  QTooltip,
} from '@quasar/components';


export default {
  name: 'VQueueMonitoringView',
  components: {
    QInput, QDate, QPopupProxy, QSelect, QTable, QIcon, QTr, QTh, QTd, QBtn, QTooltip,
  },
  props: {
    apiUrl: {
      type: String,
    },
    uriStatuses: {
      type: String,
      default: '',
    },
    withDate: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'bySocial',
    },
    exportName: {
      type: String,
      default: 'export',
    },
    sourceKindOptions: {
      type: Object,
      default: () => ({}),
    },
    withExport: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    table: {
      inFullscreen: false,
      pagination: {
        rowsPerPage: 10,
      },
    },
    rows: [],
    cols: [],
    detailHeaders: [],
    loading: false,
    filters: {
      date: {
        from: '',
        to: '',
      },
      status: '',
      source_kind: '',
    },
    statusOptions: [],
    filter: '',
  }),
  async mounted() {
    await this.$trans.add(['statistics', 'button', 'menu', 'placeholder', 'main']);
    await this.requestGetData();
    if (!this.withDate) {
      const statusRes = await requestWrapper({
        url: this.uriStatuses,
      });
      this.statusOptions = statusRes.data;
      this.statusOptions.unshift({
        label: this.trans('main.all'),
        value: '',
      });
      this.clearFilters();
    }
  },
  methods: {
    clearFilters() {
      this.filters.date.to = '';
      this.filters.date.from = '';
      this.filters.status = this.statusOptions[0].value;
      this.filters.source_kind = this.sourceKindOptions[0].value;
    },
    async requestGetData() {
      this.loading = true;
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiUrl,
        params: {
          to: this.filters.date.to,
          from: this.filters.date.from,
          status: this.filters.status,
          source_kind: this.filters.source_kind,
        },
      });

      if (!res.error) {
        const { data } = res;
        this.rows = data?.rows ?? [];
        this.cols = data?.cols ?? [];
        this.detailHeaders = data?.detailHeaders ?? [];
      }
      this.loading = false;
      this.$globalLoading.hide();
    },
    sum(col) {
      return _.sumBy(this.rows, col.name);
    },
    async exportu(e) {
      this.loading = true;
      this.$globalLoading.show();
      axios({
        url: `${this.apiUrl}`,
        method: 'GET',
        responseType: 'blob',
        params: {
          to: this.filters.date.to,
          from: this.filters.date.from,
          status: this.filters.status,
          source_kind: this.filters.source_kind,
          export: true,
        },
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        const date = new Date();

        let { exportName } = this;

        if (this.filters.date.from) {
          exportName += ` ${this.$trans.get('statistics.file.from', { date: this.filters.date.from })}`;
        }
        if (this.filters.date.to) {
          exportName += ` ${this.$trans.get('statistics.file.to', { date: this.filters.date.to })}`;
        }

        fileLink.setAttribute('download', `${exportName}.xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loading = false;
        this.$globalLoading.hide();
      });
    },
  },
  watch: {
    'filters.date.from': function () {
      this.requestGetData();
    },
    'filters.date.to': function () {
      this.requestGetData();
    },
    'filters.status': function () {
      this.requestGetData();
    },
    'filters.source_kind': function () {
      this.requestGetData();
    },
  },
};
