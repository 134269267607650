import JournalRepository
from '@app_balabaqsha/repositories/attendance/JournalRepository';
import JournalMarkRepository
from '@app_balabaqsha/repositories/attendance/JournalMarkRepository';
import JournalConfirmRepository
from '@app_balabaqsha/repositories/attendance/JournalConfirmRepository';
import AttendanceJournalApiProvider
from '@app_balabaqsha/providers/Attendance/AttendanceJournalApiProvider';

const JournalApiProvider = {
  data: () => ({
    apiProvider: {},
  }),
  methods: {
    initProvider({ journalListApi, journalMarkApi, journalConfirmApi }) {
      const trans = this.$trans.get;
      this.apiProvider = new AttendanceJournalApiProvider({
        JournalRepository: new JournalRepository(journalListApi),
        JournalMarkRepository: new JournalMarkRepository(journalMarkApi),
        JournalConfirmRepository: new JournalConfirmRepository(journalConfirmApi),
      }, trans);
    },
  },
};

export default JournalApiProvider;
