import axios from 'axios';
import { FALLBACK_LOCALE } from '@app_balabaqsha/consts';

export default class JournalMarkRepository {
  constructor({
    apiUrl,
    locale,
  }) {
    this.url = apiUrl || '';
    this.locale = locale || FALLBACK_LOCALE;
  }

  async mark(params = {}) {
    const { url, locale } = this;
    // eslint-disable-next-line no-return-await
    return await axios.post(
      `${url}/${params.contingentId}`,
      params,
      {
        headers: { 'X-localization': locale },
      },
    );
  }
}
