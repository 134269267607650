import axios from 'axios';
import { FALLBACK_LOCALE } from '@app_balabaqsha/consts';

export default class EntityListDateFetchRepository {
  constructor({ apiUrl, locale }) {
    this.apiUrl = apiUrl || '';
    this.locale = locale || FALLBACK_LOCALE;
  }

  async get({ id, date, type }) {
    // eslint-disable-next-line no-return-await
    return await axios.get(
      // eslint-disable-next-line camelcase
      `${this.apiUrl}/${id}`,
      {
        headers: { 'X-localization': this.locale },
        params: {
          date,
          type,
        },
      },
    );
  }
}
