import {
  QTd, QTr, QTh, QTable, QTooltip, QIcon, QInput,
} from '@quasar/components';
import { Helper } from '@common/src/helpers';
import VNoResults from '@vjs/components/VNoResults';
import VSignatureDataViewer from '@app_balabaqsha/components/components/VSignatureDataViewer';
import VFundingDocumentsTreeBody from '../VFundingDocumentsTreeBody';

export default {
  name: 'VFundingDocumentsMonthView',
  components: {
    QInput,
    QTd,
    QTr,
    QTh,
    QTable,
    QTooltip,
    QIcon,
    VNoResults,
    VFundingDocumentsTreeBody,
    VSignatureDataViewer,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        getTable: '',
        send: '',
        sendToRevision: '',
        uploadDirection: '',
        deleteDirection: '',
        deleteApplication: '',
        fundingDocumentId: null,
        role: 'kindergartenWorker',
      }),
    },
  },
  data() {
    return {
      table: {
        cols: [],
        rows: [],
        fundingDocumentApplication: false,
        status: 0,
        pagination: {
          rowsPerPage: 0,
        },
        fileUrl: null,
        reason: null,
        workerSignData: null,
        controlSignData: null,
        labels: [],
      },
      applicationFile: null,
      signXml: '',
    };
  },

  beforeCreate() {
    this.$trans.add(['table', 'label', 'button', 'funding_documents']);
  },

  computed: {
    getFileLabel() {
      if (!this.table.fundingDocumentApplication) {
        return this.trans('label.file_not_chosen');
      }
      if (this.table.fundingDocumentApplication && !this.applicationFile) {
        return this.trans('label.file_loaded');
      }
      return this.applicationFile.name;
    },
    sendBtnLabel() {
      return this.table.status === 0 || this.table.status === 2
        ? this.trans('button.send') : this.trans('button.sent');
    },
    disableSendBtn() {
      return !this.table.fundingDocumentApplication
        || (!this.applicationFile && this.table.status === 0)
        || [1, 3].includes(this.table.status);
    },
    disableFileBtn() {
      return this.table.fundingDocumentApplication
        && !this.applicationFile
        && this.table.status !== 2;
    },
    disableFileBtnLabel() {
      return this.table.fundingDocumentApplication
        ? this.trans('button.delete')
        : this.trans('button.add');
    },
  },
  async mounted() {
    await requestGetDataHelper(this, {
      url: this.config.getTable, variableName: 'table', params: { fundingDocumentId: this.config.fundingDocumentId }, hardSet: true,
    });
  },

  methods: {
    async getData() {
      this.signXml = '';
      await requestGetDataHelper(this, {
        url: this.config.getTable, variableName: 'table', params: { fundingDocumentId: this.config.fundingDocumentId }, hardSet: true,
      });
    },
    async handlePostRequest(url, data) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url,
        data,
        method: 'post',
      });

      if (!res.error) {
        this.applicationFile = null;
        eventBus.$emit('onCloseAll');
        await this.getData();
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
    async uploadApplicationFIle() {
      if (this.table.fundingDocumentApplication) {
        if (this.table.status !== 2) {
          this.table.fundingDocumentApplication = false;
        } else {
          await this.handlePostRequest(this.config.deleteApplication, {
            fundingDocumentId: this.config.fundingDocumentId,
          });
        }
        this.file = null;
        return;
      }
      const el = this.$refs.applicationFile;
      if (el.onclick) {
        el.onclick();
      } else if (el.click) {
        el.click();
      }
    },
    onUploadApplication(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (files.length <= 0) {
        this.table.fundingDocumentApplication = false;
        return;
      }
      // eslint-disable-next-line prefer-destructuring
      this.applicationFile = files[0];
      this.table.fundingDocumentApplication = true;
    },
    async handleSign() {
      this.$globalLoading.show();
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = '<status>Sign</status>';
      if ([webSocket.CLOSED, webSocket.CLOSING].includes(webSocket.readyState)) {
        this.$notify({
          text: this.trans('notice.ncalayer_connection_error'),
          type: 'error',
        });
        return;
      }
      webSocket.onopen = () => {
        callback = async (response) => {
          this.signXml = response.responseObject;
          const formData = new FormData();
          formData.append('fundingDocumentId', this.config.fundingDocumentId);
          formData.append('signXml', this.signXml);
          formData.append('applicationFile', this.applicationFile);
          await this.handlePostRequest(this.config.send, formData);
          this.$globalLoading.hide();
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };

      webSocket.onclose = (event) => {
        if (event.wasClean) {
          // eslint-disable-next-line no-console
          console.warn('connection has been closed');
        } else {
          this.$notify({
            text: this.trans('notice.ncalayer_connection_closed'),
            type: 'error',
          });
          this.$globalLoading.hide();
        }
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.$globalLoading.hide();
          }
        }
      };
    },
    async sendToRevision(reason) {
      await this.handlePostRequest(this.config.sendToRevision, {
        fundingDocumentId: this.config.fundingDocumentId,
        reason,
      });
    },
  },
};
