<template>
  <div
    class="faq-answear s-spoiler"
    :class="{ 'is-active': show }"
  >
    <div
      class="faq-answear__head"
      @click="show = !show"
    >
      <div class="faq-answear__title">
        {{ title }}
      </div>
      <div
        class="faq-answear__helper s-str-ico-fa-rev s-str-ico-fa--thm-chevron-double-down s-str-ico--animate-self"
        :class="show ? 'is-active' : ''"
      >
        <template v-if="$q.screen.width > 480">
          {{ trans(`label.spoiler_show_${show}`) }}
        </template>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="show"
        class="faq-answear__body"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
.s-spoiler {
  box-shadow: unset;
  border: 1px solid rgba(236, 238, 245, 1);
  margin-bottom: 2rem;

  .faq-answear {
    &__title {
      color: rgba(86, 93, 130, 1);
      text-transform: uppercase;
    }

    &__helper {
      margin-left: auto;
      min-width: 9.6rem;
      padding-right: 3rem;
      &::before {
        font-weight: 300;
      }
    }

    &__head {
      background-color: rgba(242, 242, 242, 1) !important;
      color: rgba(86, 93, 130, 1) !important;
      padding: 1.6rem 2rem;
      display: flex;
      align-items: center;

      &::after {
        content: unset;
      }
    }

    &__body {
      padding: 2rem;

      .s-desc-item {
        > div {
          color: rgba(48, 69, 167, 1);
        }

        &__label {
          font-weight: 400;
          line-height: 20px;
        }

        &__value {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .s-spoiler {
    .faq-answear__helper {
      min-width: 2.5rem;
    }
  }
}
</style>

<script>
export default {
  name: 'VSpoiler',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
    };
  },
};

</script>
